import { Box, Divider, Flex, Image, Text } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useEffect, useState } from "react";
import Header from "./Header";



function Orderdesc() {
    let navigate = useNavigate();
    let [order, setOrder] = useState({});
    let [orderprod, setOrderProd] = useState([])
    let login = JSON.parse(localStorage.getItem("login")) || "";
    let { id } = useParams()
    let [totalPrice, setTotalPrice] = useState(0);
    let [totalStrikeOffPrice, setTotalStrikeOffPrice] = useState(0);
    let [addresspro, setAddressPro] = useState({})

    const fetchOrderData = async () => {
        try {
            const response = await fetch(`https://100billionoms.com/cart-orders/order/${id}`);
            const res = await response.json();
            console.log(res.data);
    
            // Parse cart_product and user_address fields
            const parsedOrder = {
                ...res.data,
                cart_product: JSON.parse(res.data.cart_product || "[]"), // Parse cart_product JSON
                user_address: JSON.parse(res.data.user_address || "{}"), // Parse user_address JSON
                vendor_address: JSON.parse(res.data.vendor_address || "{}"), // Parse vendor_address JSON
            };
    
            // Set parsed order details
            setOrder(parsedOrder);
            setOrderProd(parsedOrder.cart_product);
            setAddressPro(parsedOrder.user_address);
    
            // Calculate total prices
            let totalPrice = 0;
            let totalStrikeOffPrice = 0;
    
            parsedOrder.cart_product.forEach((product) => {
                totalPrice += product.product.mrp_price * product.quantity;
                totalStrikeOffPrice += product.product.sell_price * product.quantity;
            });
    
            setTotalPrice(totalPrice);
            setTotalStrikeOffPrice(totalStrikeOffPrice);
        } catch (error) {
            console.log(error);
        }
    };
    

   
    console.log(totalPrice)
    console.log(totalStrikeOffPrice)

    let logout = () => {
        
        localStorage.removeItem("login");
        window.location.reload();
    }

    useEffect(() => {
        fetchOrderData();
    }, [])
    return (
        <>
        <Header/>
        <Box position={"relative"}>
           
            <Box mt={{base:"",sm:"",md:"",lg:"30px",xl:"30px"}} position={"relative"} >
                <Box boxShadow={"0 0 8px 0 rgba(0,0,0,0.1)"} borderRadius={"20px"} w={{base:"100%",sm:"100%",md:"100%",lg:"70%",xl:"70%"}} m={"auto"} mb={"20px"}>
                    <Flex>
                        
                        <Box borderLeft={"1px solid #eee"} w={{base:"100%",sm:"100%",md:"100%",lg:"100%",xl:"100%"}} p={"12px 30px 12px 30px"}>
                            <Box onClick={() => navigate("/vendor-order")} display={{base:"none",sm:"none",md:"none",lg:"block",xl:"block"}} border={"1px solid #eee"} w={"8%"} borderRadius={"10px"} p={"10px"} m={"20px 0px 0px 0px"}>
                                <i class="fa-solid fa-arrow-left fa-2x"></i>
                            </Box>
                            <Box mt={"20px"}>
                                <Text fontWeight={"bold"} fontSize={"19px"}>Order Summary</Text>
                                {
                                    order.status==="Delivered"?<Text color={"#696969"} fontSize={"13px"}>Delivered at {new Date(order.updatedAt).toLocaleString('en-IN', {
                                        timeZone: 'Asia/Kolkata',
                                        hour: 'numeric',
                                        minute: 'numeric',
                                        hour12: true,
                                    })}
                                    </Text>:null
                                }
                                {/* <Box color={"#318616"}>
                                    <Flex gap={"10px"} alignItems={"center"}>
                                        <Text fontSize={"13px"}>Download Invoice</Text>
                                        <i class="fa-solid fa-download" style={{ fontSize: "13px" }}></i>
                                    </Flex>
                                </Box> */}
                            </Box>
                            <Box mt={"20px"}>
                                <Text fontWeight={"bold"} color={"#363636"} fontSize={"15px"}>{orderprod.length} item in this order </Text>
                                <Box mt={"10px"}>
                                    {
                                        orderprod?.map((ele, idx) => {
                                            return (
                                                <Box key={idx} mt={"10px"}>
                                                    <Box>
                                                        <Flex alignItems={"center"}>
                                                            <Box >
                                                                <Flex alignItems={"center"} gap={"20px"}>
                                                                    <Image borderRadius={"10px"} border={"1px solid #e3e3e3"} w={"15%"} src={`https://100billionoms.com/${(ele.product.product_image)}`} />
                                                                    <Box>
                                                                        <Text fontWeight={"500"} fontSize={"13px"} color={"#1F1F1F"}>{ele.product.product_name}</Text>
                                                                        <Text fontSize={"12px"} color={"#696969"} mt={"30px"}>{ele.quantity}</Text>
                                                                    </Box>
                                                                </Flex>
                                                            </Box>
                                                            <Text fontWeight={"bold"} fontSize={"12px"}>₹{ele.product.sell_price}</Text>
                                                            <Box>

                                                            </Box>
                                                        </Flex>
                                                    </Box>
                                                    {/* <Box mt={"20px"} color={"1px solid #F8F8F8"} backgroundColor={"#F8F8F8"} p={"7px"}>

                                                    </Box> */}
                                                    {/* <Box mt={"20px"} >
                                                        <Divider style={{padding:"20px"}} p={"10px"}/>
                                                    </Box> */}

                                                    {/* <Box mt={"20px"} color={"1px solid #F8F8F8"} backgroundColor={"#F8F8F8"} p={"7px"}>

                                                    </Box> */}
                                                </Box>

                                            )
                                        })
                                    }
                                    <Box mt={"30px"}>
                                        <Text fontWeight={"bold"} fontSize={"15px"}>Bill Details</Text>
                                    </Box>
                                    <Box mt={'10px'}>
                                        <Divider />
                                    </Box>
                                    <Box mt={"10px"}>
                                        <Box p={"0px 12px 0px 12px"}>
                                            <Flex justifyContent={"space-between"} alignItems={"center"}>
                                                <Text m={"6px 0px 6px 0px"} fontSize={"12px"}>MRP</Text>
                                                <Text m={"6px 0px 6px 0px"} fontSize={"12px"}>₹{totalStrikeOffPrice}</Text>
                                            </Flex>
                                        </Box>
                                        <Box p={"0px 12px 0px 12px"}>
                                            <Flex justifyContent={"space-between"} alignItems={"center"}>
                                                <Text m={"6px 0px 6px 0px"} color={"#256FEF"} fontSize={"12px"}>Product discount</Text>
                                                <Text m={"6px 0px 6px 0px"} fontSize={"12px"} color={"#256FEF"}>₹{totalStrikeOffPrice - totalPrice}</Text>
                                            </Flex>
                                        </Box>
                                        <Box p={"0px 12px 0px 12px"}>
                                            <Flex justifyContent={"space-between"} alignItems={"center"}>
                                                <Text m={"6px 0px 6px 0px"} fontSize={"12px"}>Item total</Text>
                                                <Text m={"6px 0px 6px 0px"} fontSize={"12px"}>₹{totalPrice}</Text>
                                            </Flex>
                                        </Box>
                                        <Box p={"0px 12px 0px 12px"}>
                                            <Flex justifyContent={"space-between"} alignItems={"center"}>
                                                <Text m={"6px 0px 6px 0px"} fontSize={"12px"}>Handling charge</Text>
                                                <Text m={"6px 0px 6px 0px"} fontSize={"12px"}>+₹{order.handling_amount}</Text>
                                            </Flex>
                                        </Box>
                                        <Box p={"0px 12px 0px 12px"}>
                                            <Flex justifyContent={"space-between"} alignItems={"center"}>
                                                <Text m={"6px 0px 6px 0px"} fontSize={"12px"}>Delivery charges</Text>
                                                <Text m={"6px 0px 6px 0px"} fontSize={"12px"}>+₹{order.delivery_amount}</Text>
                                            </Flex>
                                        </Box>
                                        <Box p={"0px 12px 0px 12px"}>
                                            <Flex justifyContent={"space-between"} alignItems={"center"}>
                                                <Text fontWeight={"700"} m={"6px 0px 6px 0px"} fontSize={"13px"}>Bill total</Text>
                                                <Text fontWeight={"700"} m={"6px 0px 6px 0px"} fontSize={"13px"}>₹{(order.amount) / 100}</Text>
                                            </Flex>
                                        </Box>
                                    </Box>
                                    <Box mt={"20px"} color={"1px solid #F8F8F8"} backgroundColor={"#F8F8F8"} p={"7px"}>

                                    </Box>
                                    <Box mt={"20px"}>
                                        <Box >
                                            <Text fontWeight={"bold"} fontSize={"15px"}>Order details</Text>
                                        </Box>
                                        <Box mt={'10px'}>
                                            <Divider />
                                        </Box>
                                        <Box mt={"20px"}>
                                            <Box>
                                                <Text color={"#696969"} fontSize={"11px"}>Order id</Text>
                                                <Text color={"#1F1F1F"} fontSize={"13px"}>{order.transaction_id}</Text>
                                            </Box>

                                            <Box mt={"10px"}>
                                                <Text color={"#696969"} fontSize={"11px"}>Payment</Text>
                                                <Text color={"#1F1F1F"} fontSize={"13px"}>{order.payment_mode}</Text>
                                            </Box>
                                            <Box mt={"10px"}>
                                                <Text color={"#696969"} fontSize={"11px"}>Deliver to</Text>
                                                <Text color={"#1F1F1F"} fontSize={"13px"}>{addresspro.name},{addresspro.area}</Text>
                                            </Box>
                                            <Box mt={"10px"}>
                                                <Text color={"#696969"} fontSize={"11px"}>Order Placed</Text>
                                                <Text color={"#1F1F1F"} fontSize={"13px"}>
                                                    {new Date(order.updatedAt).toLocaleString('en-IN', {
                                                        timeZone: 'Asia/Kolkata',
                                                        day: 'numeric',
                                                        month: 'short',
                                                        year: 'numeric',
                                                        hour: 'numeric',
                                                        minute: 'numeric',
                                                        second: 'numeric',
                                                        hour12: false,
                                                    })}
                                                </Text>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Flex>
                </Box>
            </Box>
            </Box>
        </>
    )
}

export default Orderdesc;