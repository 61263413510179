import { useState, useEffect } from 'react';
import '../CSS/Global.css';
import Header from './Header';
import userimg from "../Images/user.jpg"
import redbg from "../Images/redcolor.jpg"

const UserProfile = () => {
    const [isEditing, setIsEditing] = useState(false);
    const login = JSON.parse(localStorage.getItem("login")) || ""
    const [profileDetails, setProfileDetails] = useState({
        id: '',
        name: '',
        mobile: '',
        email: '',
        permanent_ID: '',
        profilePic: '',
        account_number: '',
        beneficary_name: '',
        ifsc_code: '',
        bank_name: '',

    });
    const [isBankDetailsUpdated, setIsBankDetailsUpdated] = useState(false);

    // Check if bank details were already updated by checking localStorage
    // useEffect(() => {
    //     const bankUpdated = localStorage.getItem('bankDetailsUpdated');
    //     if (bankUpdated === 'true') {
    //         setIsBankDetailsUpdated(true);
    //     }
    // }, []);

    const fetchDataUser = async () => {
        try {
            const response = await fetch(`https://100billionoms.com/register/singleuser/${login.permanent_ID}`)
            const res = await response.json();
            setProfileDetails(res)
            if(res.bank_name!==null && res.beneficary_name!==null && res.account_number!==null && res.ifsc!==null){
                setIsBankDetailsUpdated(true)
            }
            console.log(res);
        } catch (error) {
            console.log(error);
        }
    }

    console.log(profileDetails);

    // const handleEditAll=()=>{
    //     if(isEditing){
    //         console.log("hi")
    //         handleEdit();
    //     }
    // }

    const [selectedFile, setSelectedFile] = useState(null);

    const handleEdit = async () => {
        const formData = new FormData();
        formData.append('name', profileDetails.name);
        formData.append('mobile', profileDetails.mobile);
        formData.append('email', profileDetails.email);
        formData.append('account_number', profileDetails.account_number);
        formData.append('beneficary_name', profileDetails.beneficary_name);
        formData.append('ifsc_code', profileDetails.ifsc_code);
        formData.append('bank_name', profileDetails.bank_name);
        if (selectedFile) {
            formData.append('profilePic', selectedFile); // Attach the selected file
        }

        try {
            const response = await fetch(`https://100billionoms.com/register/change/${profileDetails.id}`, {
                method: 'PUT',
                body: formData, // Use FormData
            });
            if (response.ok) {
                fetchDataUser(); // Refresh the profile details
                setIsEditing(false); // Exit edit mode
            }
        } catch (error) {
            console.log(error);
        }
    };
    console.log(isEditing)

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProfileDetails((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]); // Set the selected file
    };

    useEffect(() => {
        fetchDataUser();
    }, [])

    return (
        <>
            <Header />
            <div className="user-profile">
                <div className="my-profile">
                    <h2>My Profile</h2>
                    {
                        isEditing ? <button onClick={handleEdit} className={`edit-btn save`}>
                            {'Save'}
                        </button>
                            : <button onClick={() => setIsEditing(true)} className={`edit-btn edit`}>
                                {'Edit'}
                            </button>
                    }

                </div>
                <div className="user-profile-column">
                    <div className="user-profile-left">
                        <h2>Personal Information</h2>
                        <div className="user-profile-img">
                            <img src={redbg} alt="user-profile" className='user-profile-img1' />
                            <img
                                src={
                                    profileDetails.profilePic
                                        ? `https://100billionoms.com/${profileDetails.profilePic}`
                                        : userimg
                                }
                                alt="User"
                                className="user-profile-img2"
                            />

                        </div>
                        {isEditing && (
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleFileChange}
                                className="file-input"
                            />
                        )}
                        <div className="user-profile-name">
                            {isEditing ? (
                                <input
                                    type="text"
                                    name="name"
                                    value={profileDetails.name}
                                    onChange={handleChange}
                                    placeholder="Enter your name"
                                    className="input-field"
                                />
                            ) : (
                                <p className="user-name">{profileDetails.name}</p>
                            )}
                        </div>
                        <div className="user-profile-contact">
                            <p>
                                Contact: {isEditing ? (
                                    <input
                                        type="text"
                                        name="mobile"
                                        value={profileDetails.mobile}
                                        onChange={handleChange}
                                        placeholder="Enter contact number"
                                        className="input-field"
                                    />
                                ) : (
                                    <span>{profileDetails.mobile}</span>
                                )}
                            </p>
                            <p>
                                Email: {isEditing ? (
                                    <input
                                        type="email"
                                        name="email"
                                        value={profileDetails.email}
                                        onChange={handleChange}
                                        placeholder="Enter email"
                                        className="input-field"
                                    />
                                ) : (
                                    <span>{profileDetails.email}</span>
                                )}
                            </p>
                            <p>
                                Permanent ID: {isEditing ? (
                                    <input
                                        type="text"
                                        name="permanent_ID"
                                        value={profileDetails.permanent_ID}
                                        onChange={handleChange}
                                        placeholder="Enter permanent ID"
                                        className="input-field"
                                    />
                                ) : (
                                    <span>{profileDetails.permanent_ID}</span>
                                )}
                            </p>
                            {/* <p>
                                Address: {isEditing ? (
                                    <input
                                        type="text"
                                        name="address"
                                        value={profileDetails.address}
                                        onChange={handleChange}
                                        placeholder="Enter address"
                                        className="input-field"
                                    />
                                ) : (
                                    <span>{profileDetails.address}</span>
                                )}
                            </p> */}
                        </div>
                    </div>
                    <div className="user-profile-right">
                        {/* <div className="user-profile-password">
                            <h2>Privacy Details</h2>
                            <div className="password">
                                <input
                                    type="password"
                                    name="password"
                                    value={profileDetails.password}
                                    onChange={handleChange}
                                    placeholder="Enter your password"
                                    className="input-field"
                                    disabled={!isEditing}
                                />
                                <input
                                    type="password"
                                    name="confirmPassword"
                                    value={profileDetails.confirmPassword}
                                    onChange={handleChange}
                                    placeholder="Confirm your password"
                                    className="input-field"
                                    disabled={!isEditing}
                                />
                            </div>
                        </div> */}
                        <div className="user-profile-bank">
                            <h2>
                                Bank Details
                                {/* Show the span only if the bank details have not been updated */}
                                {!isBankDetailsUpdated && (
                                    <span>
                                        <i className="fa-solid fa-exclamation"></i>
                                        Update Your Bank Details
                                    </span>
                                )}
                            </h2>
                            <div className="bank-details">
                            <input
                                    type="text"
                                    name="beneficary_name"
                                    value={profileDetails.beneficary_name}
                                    onChange={handleChange}
                                    placeholder="Beneficiary's name"
                                    className="bank-input"
                                    disabled={!isEditing}
                                />
                                <input
                                    type="text"
                                    name="account_number"
                                    value={profileDetails.account_number}
                                    onChange={handleChange}
                                    placeholder="Account Number"
                                    className="bank-input"
                                    disabled={!isEditing}
                                />
                                
                                <input
                                    type="text"
                                    name="ifsc_code"
                                    value={profileDetails.ifsc_code}
                                    onChange={handleChange}
                                    placeholder="IFSC Code"
                                    className="bank-input"
                                    disabled={!isEditing}
                                />
                                <input
                                    type="text"
                                    name="bank_name"
                                    value={profileDetails.bank_name}
                                    onChange={handleChange}
                                    placeholder="Bank Name"
                                    className="bank-input"
                                    disabled={!isEditing}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserProfile;
