import React, { useState } from 'react';
import '../CSS/Global.css';

const ImageUpload = ({ onImageUpload }) => {
  const [images, setImages] = useState([]);

  const processFiles = (files) => {
    const validFiles = files.filter((file) => file.type.startsWith('image/'));
    const updatedImages = [...images, ...validFiles];
    setImages(updatedImages);
    onImageUpload(updatedImages);
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    processFiles(files);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const files = Array.from(e.dataTransfer.files);
    processFiles(files);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  // New function to handle image upload to the backend
  const uploadImages = async () => {
    const formData = new FormData();
    images.forEach((image) => {
      formData.append('images[]', image); // Append each image to the FormData
    });

    try {
      const response = await fetch('/your-backend-endpoint', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        // Handle success (e.g., show a message or clear images)
        console.log('Images uploaded successfully!');
      } else {
        // Handle error
        console.error('Error uploading images');
      }
    } catch (error) {
      console.error('Upload failed:', error);
    }
  };

  return (
    <div className="image-upload-container">
      <div
        className="drop-zone"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        style={{ border: '2px dashed #ccc', padding: '20px', minHeight: '150px' }}
      >
        <div className="upload-icon">
          <i className="fas fa-cloud-upload-alt"></i>
        </div>
        <label htmlFor="file-upload" className="browse-button">
          Browse
        </label>
        <input
          type="file"
          id="file-upload"
          multiple
          onChange={handleImageUpload}
          style={{ display: 'none' }}
        />
      </div>

      <div className="preview">
        {images.length > 0 && <h4>Uploaded Images</h4>}
        <div className="image-preview-grid">
          {images.map((image, index) => (
            <img
              key={index}
              src={URL.createObjectURL(image)}
              alt={`Uploaded ${index}`}
              className="image-preview"
            />
          ))}
        </div>
      </div>

      {/* Button to trigger the upload */}
      <button onClick={uploadImages} disabled={images.length === 0}>
        Upload Images
      </button>
    </div>
  );
};

export default ImageUpload;
