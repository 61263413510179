import '../CSS/Global.css';
import DownloadGif from '../Images/mobile screen video.gif';

const DownloadApp = () => {
    return (
        <div className="download-app-container">
            <img src={DownloadGif} alt="Download App GIF" />
            <p>Download App</p>
        </div>
    );
}

export default DownloadApp;
