import React, { useState, useEffect } from 'react';
import {
    Box,
    Heading,
    Image,
    Flex,
    Text,
    SimpleGrid,
    IconButton,
    Button,
    useBreakpointValue,
    Stack,
    Alert,
    AlertIcon,
    CloseButton,
    AlertDescription,
    AlertTitle,
    useDisclosure,
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Link, useNavigate } from 'react-router-dom';
import BigBullWorldAds from './BigbullWorldAds';
import VendorCard from './Vendor';
import Header from './Header';
import BigbullWorldAdsTop from './BigbullWorldAdsTop';


const CategoryProducts = () => {
    const [categories, setCategories] = useState([]);
    const [stores, setStores] = useState([]);
    const [ratingsSummary, setRatingsSummary] = useState({});
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const login = JSON.parse(localStorage.getItem("login")) || "";
    const [storesingle, setStoreSingle] = useState({})
    const {
        isOpen: isVisible,
        onClose,
        onOpen,
      } = useDisclosure({ defaultIsOpen: true })

    // Fetch categories
    const categoryFetch = async () => {
        try {
            const response = await fetch('https://100billionoms.com/category/getcategory');
            const res = await response.json();
            setCategories(res);
        } catch (error) {
            setError('Failed to fetch categories');
            console.error(error);
        }
    };


    const parseStoreData = (store) => {
        return {
            ...store,
            businessDetails: JSON.parse(store.businessDetails || "{}"),
            businessTiming: JSON.parse(store.businessTiming || "{}"),
            contactDetails: JSON.parse(store.contactDetails || "{}"),
            formImages: JSON.parse(store.formImages || "[]"),
            additionalServices: JSON.parse(store.additionalServices || "[]"),
            paymentDetails: JSON.parse(store.paymentDetails || "{}"),
            servicesDetails: JSON.parse(store.servicesDetails || "{}"),
        };
    };

    // Fetch stores
    const Allstorefetch = async () => {
        try {
            const response = await fetch('https://100billionoms.com/store/getstore/user');
            const res = await response.json();
            console.log(res);
            setStores(res.map(parseStoreData));
        } catch (error) {
            setError('Failed to fetch stores');
            console.error(error);
        }
    };

    // Fetch ratings summary for each store
    const fetchRatingsSummary = async (storeId) => {
        try {
            const response = await fetch(`https://100billionoms.com/rating/summary/${storeId}`);
            const res = await response.json();
            if (!res.isError) {
                setRatingsSummary((prevSummary) => ({
                    ...prevSummary,
                    [storeId]: {
                        count: res.count || 0,
                        average: res.average || 0,
                    },
                }));
            }
        } catch (error) {
            console.error('Failed to fetch ratings summary:', error);
        }
    };

    // Truncate store name if too long
    const truncateStoreName = (name) =>
        name.length > 20 ? name.slice(0, 20) + '...' : name;

    // Fetch categories and stores on component mount
    const singleStoreFetch = async () => {
        try {
            const response = await fetch(`https://100billionoms.com/store/getstorelist/mystore/${login.permanent_ID}`)
            const res = await response.json();
            console.log(res);
            setStoreSingle(res);
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        categoryFetch();
        Allstorefetch();
        singleStoreFetch();
    }, []);

    // Fetch ratings summary for all stores
    useEffect(() => {
        stores.forEach((store) => {
            fetchRatingsSummary(store.user_id);
        });
    }, [stores]);

    console.log(stores.status);
    // Store the breakpoint value at the top level of the component
    const chunkSize = useBreakpointValue({ base: 1, sm: 2, md: 3, lg: 4 });

    // Handle Carousel Slide Movement
    const [currentSlide, setCurrentSlide] = useState({});
    const handleSlide = (category, direction, length) => {
        setCurrentSlide((prev) => ({
            ...prev,
            [category]: Math.min(
                Math.max((prev[category] || 0) + direction, 0),
                length - 1
            ),
        }));
    };

    const handleWhatsAppClick = (storeName, mobile) => {
        const message = `Hi, I would like to inquire about ${storeName}`;
        const phoneNumber = `+91${mobile}`; // Replace with the store's WhatsApp number
        window.open(`https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`, '_blank');
    };


    return (
        <>
            <Header />
            <Box p={5}>
                <BigbullWorldAdsTop />
                {
                    storesingle.status === "Deactive"&& isVisible  ? <>
                        {/* <Stack spacing={3}> */}
                            <Alert status='success'>
                                <AlertIcon />
                                <Box>
                                    {/* <AlertTitle>Success!</AlertTitle> */}
                                    <AlertDescription>
                                    Your Store Status is under verification, it will get listed as soon as verification is done
                                    </AlertDescription>
                                </Box>
                                <CloseButton
                                    alignSelf='flex-end'
                                    position='relative'
                                    right={-1}
                                    top={-1}
                                    onClick={onClose}
                                />
                            </Alert>
                        {/* </Stack> */}
                    </> : null
                }
                <>
                    {categories.map((category) => {
                        const filteredStores = stores.filter(
                            (store) =>
                                store.category === category.category_name && store.status === 'Active'
                        );

                        if (filteredStores.length === 0) return null;

                        const chunkedStores = Array.from(
                            { length: Math.ceil(filteredStores.length / chunkSize) },
                            (_, i) => filteredStores.slice(i * chunkSize, i * chunkSize + chunkSize)
                        );

                        const currentIndex = currentSlide[category.category_name] || 0;

                        return (
                            <>
                                <Box key={category.category_name} mt={"30px"} mb={10} position="relative">
                                    {/* Category Header */}
                                    <Text fontSize='2xl' mb={4} >
                                        {category.category_name}
                                    </Text>

                                    {/* Left Slide Button */}
                                    <IconButton
                                        icon={<ChevronLeftIcon boxSize={8} />}
                                        aria-label="Previous"
                                        isDisabled={currentIndex === 0}
                                        onClick={() =>
                                            handleSlide(category.category_name, -1, chunkedStores.length)
                                        }
                                        position="absolute"
                                        top="50%"
                                        left="0"
                                        transform="translateY(-50%)"
                                        zIndex="1"
                                        borderRadius="full"
                                        bg="white"
                                        boxShadow="md"
                                        _hover={{ bg: 'gray.100' }}
                                    />

                                    {/* Right Slide Button */}
                                    <IconButton
                                        icon={<ChevronRightIcon boxSize={8} />}
                                        aria-label="Next"
                                        isDisabled={currentIndex === chunkedStores.length - 1}
                                        onClick={() =>
                                            handleSlide(category.category_name, 1, chunkedStores.length)
                                        }
                                        position="absolute"
                                        top="50%"
                                        right="0"
                                        transform="translateY(-50%)"
                                        zIndex="1"
                                        borderRadius="full"
                                        bg="white"
                                        boxShadow="md"
                                        _hover={{ bg: 'gray.100' }}
                                    />

                                    {/* Store Grid */}
                                    <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 4 }} spacing={5}>
                                        {chunkedStores[currentIndex]?.map((store) => (
                                            <Box
                                                key={store.id}
                                                borderWidth="1px"
                                                borderRadius="lg"
                                                overflow="hidden"
                                                boxShadow="md"

                                            >
                                                <Link to={`/detail-listing/${store.id}/${store.businessDetails.businessName}`}>
                                                    <Image
                                                        src={`https://100billionoms.com/${store.formImages[0]}`}
                                                        alt={store.name}
                                                        objectFit="cover"
                                                        height="200px"
                                                        width="100%"
                                                    />
                                                </Link>
                                                <Box p={4}>
                                                    <Link to={`/detail-listing/${store.id}/${store.businessDetails.businessName}`}>
                                                        <Text fontWeight="bold" textAlign={"center"} fontSize={"18px"} mb={"10px"}>
                                                            {truncateStoreName(store.businessDetails.businessName)}
                                                        </Text>
                                                        <Box mb={"15px"}>
                                                            <Flex gap={"10px"} alignItems="center"
                                                                justifyContent="center"
                                                                margin="auto">
                                                                <Text color="gray.600" fontSize='sm' >
                                                                    {ratingsSummary[store.user_id]?.average || 0}{' '}
                                                                    <i className="fa-solid fa-star"></i>
                                                                </Text>
                                                                <Text fontSize='sm' >
                                                                    {ratingsSummary[store.user_id]?.count || 0} Ratings
                                                                </Text>
                                                                {
                                                                    store && store.verified === true ? <Text color="green.500" fontSize='sm'>
                                                                        <i className="fa-solid fa-circle-check"></i> Verified
                                                                    </Text> : null
                                                                }

                                                            </Flex>
                                                        </Box>
                                                    </Link>
                                                    <Flex mt={2} justify="space-between">
                                                        <Button
                                                            type='button'
                                                            colorScheme="red"
                                                            leftIcon={<i className="fa-solid fa-phone"></i>}
                                                            onClick={() => navigate(`tel:${store.contactDetails.mobile}`)}
                                                        >
                                                            Contact
                                                        </Button>
                                                        <Button
                                                            type='button'
                                                            colorScheme="green"
                                                            leftIcon={<i className="fa-brands fa-whatsapp"></i>}
                                                            onClick={() => handleWhatsAppClick(store.businessDetails.businessName, store.contactDetails.whatsapp)}
                                                        >
                                                            WhatsApp
                                                        </Button>
                                                    </Flex>
                                                </Box>

                                            </Box>
                                        ))}
                                    </SimpleGrid>
                                </Box>
                            </>
                        );
                    })}
                </>
                <VendorCard />
                <BigBullWorldAds />
            </Box>
        </>
    );
};

export default CategoryProducts;
