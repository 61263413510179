import React, { useState } from 'react';
import './Table.css';  // Importing the CSS file

const VendorListing = () => {
    // Sample vendor data
    const vendorData = [
        {
            email: 'rajesh@metro.com',
            storeName: 'Metro Electronics',
            address: 'Metro Plaza, MG Road, CBD Area, Bangalore, Karnataka',
            contactNumber: '9876543210',
            category: 'Electronics',
            subCategory: 'Mobile Phones',
            commission: '5%',
            password: 'password123'
        },
        {
            email: 'suresh@citymart.com',
            storeName: 'City Mart',
            address: 'City Centre, Church Street, Central Bangalore, Karnataka',
            contactNumber: '9876543211',
            category: 'Groceries',
            subCategory: 'Fruits & Vegetables',
            commission: '5%',
            password: 'password123'
        },
        {
            email: 'amit@techworld.com',
            storeName: 'Tech World',
            address: 'Tech Park, Indiranagar, East Bangalore, Karnataka',
            contactNumber: '9876543212',
            category: 'Electronics',
            subCategory: 'Computers',
            commission: '5%',
            password: 'password123'
        },
        {
            email: 'nisha@groceryhub.com',
            storeName: 'Grocery Hub',
            address: 'Grocery Plaza, Koramangala, South Bangalore, Karnataka',
            contactNumber: '9876543213',
            category: 'Groceries',
            subCategory: 'Dairy Products',
            commission: '5%',
            password: 'password123'
        },
        {
            email: 'priya@fashioncorner.com',
            storeName: 'Fashion Corner',
            address: 'Fashion Mall, MG Road, Bangalore, Karnataka',
            contactNumber: '9876543214',
            category: 'Fashion',
            subCategory: 'Clothing',
            commission: '5%',
            password: 'password123'
        },
    ];

    const [searchTerm, setSearchTerm] = useState('');

    const filteredVendors = vendorData.filter(vendor =>
        vendor.storeName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="table-container">
            <h2>Vendor Registration Listing</h2>
            <div className="search-container">
                <input
                    type="text"
                    placeholder="Search by store name..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>
            <div className="table-wrapper">
                <table className="bigbull-table">
                    <thead>
                        <tr>
                            <th>Email</th>
                            <th>Store Name</th>
                            <th>Address</th>
                            <th>Contact Number</th>
                            <th>Category</th>
                            <th>Sub Category</th>
                            <th>Commission</th>
                            <th>Password</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredVendors.map((vendor, index) => (
                            <tr key={index}>
                                <td>{vendor.email}</td>
                                <td>{vendor.storeName}</td>
                                <td>{vendor.address}</td>
                                <td>{vendor.contactNumber}</td>
                                <td>{vendor.category}</td>
                                <td>{vendor.subCategory}</td>
                                <td>{vendor.commission}</td>
                                <td>{vendor.password}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default VendorListing;
