import React, { useEffect, useState } from 'react';
import '../CSS/Global.css'; // Ensure your CSS file path is correct
import { useNavigate } from 'react-router-dom';
import productadd from "../Images/productadd.png"
import { Spinner, useToast } from '@chakra-ui/react';

const AddProduct = () => {
  const [productName, setProductName] = useState('');
  const [mrp, setMrp] = useState('');
  const [sellingPrice, setSellingPrice] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [image, setImage] = useState()
  const login = JSON.parse(localStorage.getItem("login")) || ""
  let navigate = useNavigate()
  const [units, setUnits] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState('');
  const [quantity, setSelectedQuantity] = useState('');
  const toast = useToast()
  const [load, setLoad] = useState(true);

  const handleImageUpload = (event) => {
    const file = event.target.files[0]; // Get the first file from input
    setImage(file); // Store the selected image in state
  };

  // Add form submission logic here

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoad(false);
    // Create FormData object to send image along with other data
    const formData = new FormData();
    formData.append('product_image', image);
    formData.append('product_name', productName);
    formData.append('mrp_price', mrp);
    formData.append('sell_price', sellingPrice);
    formData.append('quantity', quantity);
    formData.append('unit', selectedUnit);
    formData.append('description', shortDescription);
    formData.append('user_id', login.permanent_ID);
    formData.append('user_name', login.name);

    try {
      // Replace with your backend API URL
      const response = await fetch(`https://100billionoms.com/product/add-product/${login.permanent_ID}`, {
        method: 'POST',
        body: formData,
      });

      const result = await response.json();
      console.log('Image uploaded:', result);
      if (response.ok) {
        toast({
          description: `Product added successfully`,
          status: 'success',
          duration: 5000,
          position: 'top-center',
          isClosable: true,
        });
        navigate(-1)
        setLoad(true)
      }
    } catch (error) {
      console.log('Error uploading image:', error);
      setLoad(true)
    }
    // console.log({
    //   productName,
    //   mrp,
    //   sellingPrice,
    //   shortDescription,
    // });
  };

  const [category, setCategory] = useState();

  const fetchDataUser = async () => {
    try {
      const response = await fetch(`https://100billionoms.com/store/getstorelist/mystore/${login.permanent_ID}`);
      const store = await response.json();
      console.log(store);
      setCategory(store.category)
    } catch (error) {
      console.log(error);
    }
  }

  const fetchCategory = async () => {
    try {
      const response = await fetch(`https://100billionoms.com/category/singlecategory/${category}`);
      const res = await response.json();
      console.log(res);

      // Parse the units field
      if (res.units) {
        const parsedUnits = JSON.parse(res.units);
        console.log('Parsed Units:', parsedUnits);
        setUnits(parsedUnits);
      } else {
        console.log('Units field is not available');
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (category) {
      fetchCategory();
    }
  }, [category]);


  useEffect(() => {
    fetchDataUser();
    // fetchCategory();
  }, [])
  console.log(selectedUnit);


  return (
    <div className="product-container">
      <div className="product-image-column">
        <img
          src={productadd}
          alt="Product"
          className="product-image"
        />
      </div>
      <div className="product-form-column">
        <h2>List Your Product</h2>
        <form onSubmit={handleSubmit}>
          <div className="product-form-group">
            <label>Product Name:</label>
            <input
              type="text"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              placeholder="Enter product name"
              required
            />
          </div>
          <div className="product-form-group">
            <label>Upload Product Image:</label>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              required
            />
          </div>
          <div className="price-container">
            <div className="product-form-group">
              <label>MRP:</label>
              <input
                type="number"
                value={mrp}
                onChange={(e) => setMrp(e.target.value)}
                placeholder="Enter MRP"
                required
              />
            </div>
            <div className="product-form-group">
              <label>Selling Price:</label>
              <input
                type="number"
                value={sellingPrice}
                onChange={(e) => setSellingPrice(e.target.value)}
                placeholder="Enter Selling Price"
                required
              />
            </div>
          </div>
          <div className="price-container">
            <div className="product-form-group">
              <label>Unit:</label>
              <select
                className='bestselecttagforec'
                value={selectedUnit}
                onChange={(e) => setSelectedUnit(e.target.value)}
                required
              >
                <option value="">Select Unit</option>
                {units.map((unit, index) => (
                  <option key={index} value={unit}>
                    {unit}
                  </option>
                ))}
              </select>
            </div>
            <div className="product-form-group">
              <label>Quantity {selectedUnit === "" ? null : ("(" + selectedUnit + ")")}:</label>
              <input
                type="quantity"
                value={quantity}
                onChange={(e) => setSelectedQuantity(e.target.value)}
                placeholder={`Enter Quantity in ${selectedUnit}`}
                required
              />
            </div>

          </div>

          <div className="product-form-group">
            <label>Short Description (max 50 chars):</label>
            <input
              type="text"
              value={shortDescription}
              onChange={(e) => setShortDescription(e.target.value)}
              maxLength="50"
              placeholder="Enter short description"
              required
            />
          </div>
          {
            load?<button type="submit" className="product-submit-button">Add Product</button>:
            <button  className="product-submit-button"><Spinner/></button>
          }
        </form>
      </div>
    </div>
  );
};

export default AddProduct;
