import React, { useState, useEffect, useRef } from 'react';
import '../CSS/Global.css';
import vendorFormImage from '../Images/vendor-form-image.webp';
import ImageUpload from './ImageUpload';
import { Badge, Box, Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Spinner, Switch, Text, useDisclosure, useToast } from '@chakra-ui/react';
import Header from './Header';
import { Autocomplete, useLoadScript } from '@react-google-maps/api';
import { CloseIcon } from '@chakra-ui/icons';
import {
    Heading,
    VStack,
    HStack,
    Divider,
    useColorModeValue,
} from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const ListingForm = () => {
    const [step, setStep] = useState(0);
    const [store, setStore] = useState(false);
    const [businessDetails, setBusinessDetails] = useState({});
    const [contactDetails, setContactDetails] = useState({});
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [google_address, setGoogleAddress] = useState()
    const [businessDate, setBusinessdate] = useState();
    const [focusedField, setFocusedField] = useState('');

    // const [businessTiming, setBusinessTiming] = useState({
    //     timeSlots: [{ openTime: '', closeTime: '' }], // Default time slot
    //     selectedDays: {},
    // });
    const [businessTiming, setBusinessTiming] = useState({
        timeSlots: [{ day: '', openTime: '', closeTime: '' }], // Each time slot has a single day
    });
    const [formImages, setFormImages] = useState([]);
    const { isOpen: isModal1Open, onOpen: openModal1, onClose: closeModal1 } = useDisclosure();
    const { isOpen: isModal2Open, onOpen: openModal2, onClose: closeModal2 } = useDisclosure();
    const login = JSON.parse(localStorage.getItem("login")) || "";
    const autocompleteRef = useRef(null);
    const autocompleteRef1 = useRef(null);
    const [vendor_location, setVendorLocation] = useState()
    const [selectedLocation, setSelectedLocation] = useState({
        lat: "",
        lng: '',
    });
    const [cities, setCities] = useState([]);
    const [plan, setPlan] = useState([])
    const [scrollBehavior, setScrollBehavior] = React.useState('inside')
    const libraries = ['places'];

    const handleFormImageUpload = (uploadedImages) => {
        setFormImages(uploadedImages);
    };

    const toast = useToast();
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: 'AIzaSyD0AcxoBWhqkMIAfK-F-X6ya3Es-52o1YU', // Replace with your API key
        libraries,
    });


    const [paymentDetails, setPaymentDetails] = useState({});
    const [servicesDetails, setServicesDetails] = useState({});
    //const [images, setImages] = useState([]);

    // Track additional input fields for mobile, WhatsApp, landline, email
    const [additionalMobiles, setAdditionalMobiles] = useState([]);
    const [additionalWhatsapps, setAdditionalWhatsapps] = useState([]);
    const [additionalLandlines, setAdditionalLandlines] = useState([]);
    const [additionalEmails, setAdditionalEmails] = useState([]);
    const [additionalServices, setAdditionalServices] = useState([]);
    const [planobject, setPlanObject] = useState({})
    const [gst, setGst] = useState([]);
    const [load, setLoad] = useState(true);

    // const handleImageUpload = (e) => {
    //     const files = Array.from(e.target.files);
    //     setImages([...images, ...files]);
    // };
    // Updated handlers for additional fields
    const handleAdditionalMobileChange = (index, e) => {
        const updatedMobiles = [...additionalMobiles];
        updatedMobiles[index] = e.target.value;
        setAdditionalMobiles(updatedMobiles);
    };

    const handleAdditionalWhatsappChange = (index, e) => {
        const updatedWhatsapps = [...additionalWhatsapps];
        updatedWhatsapps[index] = e.target.value;
        setAdditionalWhatsapps(updatedWhatsapps);
    };

    const handleAdditionalEmailChange = (index, e) => {
        const updatedEmails = [...additionalEmails];
        updatedEmails[index] = e.target.value;
        setAdditionalEmails(updatedEmails);
    };

    const handleAdditionalLandlineChange = (index, e) => {
        const updatedLandlines = [...additionalLandlines];
        updatedLandlines[index] = e.target.value;
        setAdditionalLandlines(updatedLandlines);
    };

    const handleAdditionalServicesChange = (index, e) => {
        const updatedServices = [...additionalServices];
        updatedServices[index] = e.target.value;
        setAdditionalServices(updatedServices);
    };


    const handleBusinessDetailsChange = (e) => {
        setBusinessDetails({ ...businessDetails, [e.target.name]: e.target.value });
    };

    const handleContactDetailsChange = (e) => {
        setContactDetails({ ...contactDetails, [e.target.name]: e.target.value });
    };

    const handlePaymentDetailsChange = (e) => {
        setPaymentDetails({ ...paymentDetails, [e.target.name]: e.target.value });
    };

    const handleServicesDetailsChange = (e) => {
        setServicesDetails({ ...servicesDetails, [e.target.name]: e.target.value });
    };

    // const handleImageUpload = (e) => {
    //     const files = Array.from(e.target.files);
    //     setImages([...images, ...files]);
    // };

    const handleNextStep = () => {
        if (!validateStep(step)) {
            toast({
                // title: "Validation Error",
                description: "Please fill all the required fields.",
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "top",
            });
            return;
        }
        setStep(step + 1);
    };

    const validateStep = (currentStep) => {
        switch (currentStep) {
            case 0: // Step 1: Store Details
                const { businessName, buildingName, street, city, state, pincode, description } = businessDetails;
                if (!businessName || !buildingName || !street || !city || !state || !pincode || !description) {
                    return false;
                }
                return true;

            case 1: // Step 2: Contact Details
                const { title, personName, mobile, email } = contactDetails;
                if (!title || !personName || !mobile || !email) {
                    return false;
                }
                return true;

            case 2: // Step 3: Store Timing
            if (!businessTiming.timeSlots.every(slot => slot.day && slot.openTime && slot.closeTime)) {
                return false;
            }
            return true;

            case 3: // Step 4: Services & Category
                if (!servicesDetails.service || !selectedCategory || !gst.gstAvailable || (gst.gstAvailable === "Yes" && !gst.gstNumber)) {
                    return false;
                }
                return true;

            case 4: // Step 5: Photos
                if (formImages.length === 0) {
                    return false;
                }
                return true;

            case 5: // Step 6: Payment Details
                const { beneficiaryName, accountNumber, bankName, ifscCode } = paymentDetails;
                if (!beneficiaryName || !accountNumber || !bankName || !ifscCode) {
                    return false;
                }
                return true;

            default:
                return true;
        }
    };


    const handlePrevStep = () => {
        setStep(step - 1);
    };

    // Handlers to add new input fields
    const addNewMobileField = () => {
        setAdditionalMobiles([...additionalMobiles, '']);
    };

    const addNewWhatsAppField = () => {
        setAdditionalWhatsapps([...additionalWhatsapps, '']);
    };

    const addNewLandlineField = () => {
        setAdditionalLandlines([...additionalLandlines, '']);
    };

    const addNewEmailField = () => {
        setAdditionalEmails([...additionalEmails, '']);
    };

    const addNewServicesField = () => {
        setAdditionalServices([...additionalServices, '']);
    };

    // const handleAddTimeSlot = () => {
    //     setBusinessTiming(prev => ({
    //         ...prev,
    //         timeSlots: [...prev.timeSlots, { openTime: '', closeTime: '' }] // Add a new time slot
    //     }));
    // };

    const handleTimeSlotChange = (index, field, value) => {
        const updatedTimeSlots = [...businessTiming.timeSlots];
        updatedTimeSlots[index][field] = value;
        setBusinessTiming({ ...businessTiming, timeSlots: updatedTimeSlots });
    };

    const handleAddTimeSlot = () => {
        setBusinessTiming((prev) => ({
            ...prev,
            timeSlots: [...prev.timeSlots, { day: '', openTime: '', closeTime: '' }],
        }));
    };

    const handleRemoveTimeSlot = (index) => {
        const updatedTimeSlots = businessTiming.timeSlots.filter((_, i) => i !== index);
        setBusinessTiming({ ...businessTiming, timeSlots: updatedTimeSlots });
    };

    // const handleTimeSlotChange = (index, e) => {
    //     const updatedTimeSlots = [...businessTiming.timeSlots];
    //     updatedTimeSlots[index][e.target.name] = e.target.value;
    //     setBusinessTiming({ ...businessTiming, timeSlots: updatedTimeSlots });
    // };

    // const handleTimeSlotChange = (index, field, value) => {
    //     const updatedTimeSlots = [...businessTiming.timeSlots];
    //     updatedTimeSlots[index][field] = value;
    //     setBusinessTiming({ ...businessTiming, timeSlots: updatedTimeSlots });
    // };


    const handleCheckboxChange = (day) => {
        setBusinessTiming(prev => ({
            ...prev,
            selectedDays: {
                ...prev.selectedDays,
                [day]: !prev.selectedDays[day],
            },
        }));
    };

    const [categories, setCategories] = useState([]); // All categories
    const [selectedCategory, setSelectedCategory] = useState(null); // Selected category ID
    const [subCategories, setSubCategories] = useState([]); // Subcategories of selected category
    const [selectedSubCategory, setSelectedSubCategory] = useState("");
    const [googleAddress, setGoogleAddresss] = useState("");


    const renderTimeSlots = () => (
        <div>
            {businessTiming.timeSlots.map((slot, index) => (
                <div key={index} className="time-slot">
                    <div className="time-slot-list">
                        {/* Day Selection */}
                        <select
                            name="day"
                            value={slot.day}
                            onChange={(e) => handleTimeSlotChange(index, 'day', e.target.value)}
                        >
                            <option value="">Select Day</option>
                            {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Mon-Fri', 'Mon-Sat', 'All-Days'].map((day) => (
                                <option key={day} value={day}>
                                    {day}
                                </option>
                            ))}
                        </select>

                        {/* Open Time Selection */}
                        <select
                            name="openTime"
                            value={slot.openTime}
                            onChange={(e) => handleTimeSlotChange(index, 'openTime', e.target.value)}
                        >
                            <option value="">Select Open Time</option>
                            {Array.from({ length: 24 }, (_, i) => (
                                <option key={i} value={`${i < 10 ? '0' : ''}${i}:00`}>
                                    {`${i < 10 ? '0' : ''}${i}:00`}
                                </option>
                            ))}
                        </select>

                        {/* Close Time Selection */}
                        <select
                            name="closeTime"
                            value={slot.closeTime}
                            onChange={(e) => handleTimeSlotChange(index, 'closeTime', e.target.value)}
                        >
                            <option value="">Select Close Time</option>
                            {Array.from({ length: 24 }, (_, i) => (
                                <option key={i} value={`${i < 10 ? '0' : ''}${i}:00`}>
                                    {`${i < 10 ? '0' : ''}${i}:00`}
                                </option>
                            ))}
                        </select>
                    </div>

                    <button className="listing-add-button" onClick={() => handleRemoveTimeSlot(index)}>
                        Remove
                    </button>
                </div>
            ))}
        </div>
    );

    const getPlan = async () => {
        try {
            const response = await fetch(`https://100billionoms.com/vendor-storepricing/all/pricingall`)
            const res = await response.json();
            setPlan(res);
            console.log(res);
        } catch (error) {
            console.log(error);
        }

    }

    const plans = [
        {
            name: "BASIC",
            price: "$4.99",
            period: "/mon",
            features: "Best Plan for 1 year",
            highlight: false,
        },
        {
            name: "STANDARD",
            price: "$9.99",
            period: "/mon",
            features: "Best Plan for 1 year",
            highlight: true,
        },
        {
            name: "PREMIUM",
            price: "$14.99",
            period: "/mon",
            features: "Best Plan for 1 year",
            highlight: true,
        },

    ];

    const navigate = useNavigate()
    // Fetch categories on component mount
    const fetchDataCategory = async () => {
        try {
            const response = await fetch(`https://100billionoms.com/category/getcategory/catall`);
            const res = await response.json();
            console.log(res);
            setCategories(res); // Store fetched categories
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };

    const handleCategoryChange = (e) => {
        const categoryId = e.target.value;
        setSelectedCategory(categoryId);

        // Find the selected category's associated subcategories
        const selectedCat = categories.find(cat => cat.category_name === (categoryId));
        console.log(selectedCat)
        setSubCategories(selectedCat?.associatedsubcategory || []);
        setSelectedSubCategory(null); // Reset subcategory on category change
    };

    // Handle subcategory selection
    const handleSubCategoryChange = (e) => {
        setSelectedSubCategory(e.target.value);
    };

    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false)
            }
            document.body.appendChild(script);
        })
    };
console.log(paymentDetails.accountNumber === paymentDetails.confirmaccountNumber)
    const handlePayment = async () => {
        setLoad(false);
        if (paymentDetails.accountNumber === paymentDetails.confirmaccountNumber) {
            toast({
                // title: "Validation Error",
                description: "Account Number doesn't matches",
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "top",
            });
            // return;
        }else{
            try {
                let orderId = "11" + Math.floor(Math.random() * Math.floor(Math.random() * Date.now()));
    
                // Load the Razorpay SDK
                let res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
    
                if (!res) {
                    alert("Razorpay SDK Failed to load. Are you online?");
                    return;
                }
    
                // Define payment details
                let paymentDetails = {
                    user_id: login.permanent_ID,
                    user_phone: login.name,
                    transaction_id: orderId,
                    amount: parseInt(planobject.sale_price) * 100,
                    validity: planobject.validity,
                    currency: "INR",
                    payment_capture: 1,
                    payment_mode: "online",
                };
    
                // Create order options
                let options = {
                    key: "rzp_test_zEWr4dOpfx4w2F",
                    currency: paymentDetails.currency,
                    amount: paymentDetails.amount,
                    name: "BigBull Paisewala",
                    description: "Test Transaction",
                    handler: async function (response) {
    
                        if (response.razorpay_payment_id) {
                            // Payment was successful
                            paymentDetails.razorpay_payment_id = response.razorpay_payment_id;
                            paymentDetails.status = 'Paid';
                            let result = await axios.post(`https://100billionoms.com/pay-storefees`, paymentDetails);
    
                            if (!result.data.isError) {
                                // Redirect to the success page
                                //   checkPaymentStatus();
                                handleSubmit(result.data.payments)
                                navigate("/world")
                                setLoad(true);
                                // setLoad(false);
                                // window.location.href = `/delivery/${orderId}`; // Replace "/success-page" with the URL of your success page
                            } else {
                                alert("Server error. Are You Online");
                                setLoad(true);
                                // setLoad(false);
                                //   window.location.href = "/error-page"; // Redirect to an error page
                            }
                        } else {
                            // Handle payment failure or cancellation
    
                            paymentDetails.status = 'Failed';
                            const payment = "Failed"
                            await axios.post(`https://100billionoms.com/pay-storefees`, paymentDetails);
                            handleSubmit(payment)
                            navigate("/world")
                            setLoad(true);
                            // window.location.href = "/error-page"; // Redirect to an error page
                        }
                    },
                    prefill: {
                        email: "pankajsbisht143@gmail.com",
                        contact: "9021733853"
                    },
                    notes: {
                        address: "Razorpay Corporate Office"
                    },
                    theme: {
                        color: "#1f5215"
                    }
                };
    
                // Initialize Razorpay payment object and open checkout
                let paymentObject = new window.Razorpay(options);
                paymentObject.on('payment.failed', function (response) {
                    paymentDetails.status = 'Failed';
                    const payment = "Failed"
                    axios.post(`https://100billionoms.com/pay-storefees`, paymentDetails)
                        .then(() => {
                            navigate("/world") // Redirect to an error page
                            handleSubmit(payment)
                            setLoad(true);
    
                        }).catch(error => {
                            navigate("/world") // Redirect to an error page
                            // setShowModal(false); // Close the modal on success
                            // setLoad(false)
                            setLoad(true);
                        });
                });
    
                paymentObject.open();
    
            } catch (error) {
                console.log(error);
                setLoad(true); 
            }
        }
    }

    // console.log(formData)

    console.log(businessTiming)

    // Append business details
    const handleSubmit = (statuspay) => {
        if (statuspay === "Paid") {
            const formData = new FormData();
            // Append business details
            formData.append('businessDetails', JSON.stringify(businessDetails));

            // Append contact details
            formData.append('contactDetails', JSON.stringify(contactDetails));
            formData.append('google_address', google_address);
            // Append payment details
            formData.append('paymentDetails', JSON.stringify(paymentDetails));

            // Append services details
            formData.append('servicesDetails', JSON.stringify(servicesDetails));
            formData.append('businessTiming', JSON.stringify(businessTiming));

            formData.append('category', selectedCategory);
            formData.append('subcategory', selectedSubCategory);

            formData.append('user_id', login.permanent_ID);
            formData.append('user_name', login.name);
            formData.append('businessDate', businessDate);
            formData.append('planObject', JSON.stringify(planobject));
            formData.append('gst_no', gst.gstNumber);
            formData.append('gst_avail', gst.gst_avail);
            formData.append('location_lat', selectedLocation.lat);
            formData.append('location_long', selectedLocation.lng);

            formData.append('additionalMobiles', JSON.stringify(additionalMobiles));
            formData.append('additionalWhatsapps', JSON.stringify(additionalWhatsapps));
            formData.append('additionalEmails', JSON.stringify(additionalEmails));
            formData.append('additionalLandlines', JSON.stringify(additionalLandlines));
            formData.append('additionalServices', JSON.stringify(additionalServices));
            // Append uploaded images
            formImages.forEach((image, index) => {
                formData.append(`formImages`, image); // Ensure formImages are appended correctly
            });

            // Debug: Check FormData contents
            for (let [key, value] of formData.entries()) {
                console.log(key, value);
            }

            // Submit form data
            fetch(`https://100billionoms.com/store/add-store/${login.permanent_ID}`, {
                method: 'POST',
                body: formData,
            })
                .then(response => {
                    if (response.ok) {
                        console.log('Form submitted successfully');

                    } else {
                        console.log(response)
                        toast({
                            // title: "Validation Error",
                            description: `Store already registered`,
                            status: "error",
                            duration: 5000,
                            isClosable: true,
                            position: "top",
                        });
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                    toast({
                        // title: "Validation Error",
                        description: `${error}`,
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                        position: "top",
                    });
                });
        } else {
            toast({
                // title: "Validation Error",
                description: `Payment Failed`,
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "top",
            });
        }
    };

    // 




    const handleSelectAllDays = () => {
        const areAllSelected = Object.keys(businessTiming.selectedDays).length === 7 && Object.values(businessTiming.selectedDays).every(Boolean);
        const updatedSelectedDays = {};

        ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].forEach(day => {
            updatedSelectedDays[day] = !areAllSelected;
        });

        setBusinessTiming(prev => ({
            ...prev,
            selectedDays: updatedSelectedDays,
        }));
    };



    const handleSkip = () => {
        // Skip the step without validating or saving the current data
        console.log('Skipped current step!');
        setStep(step + 1); // Proceed to the next step
    };

    const removeMobileField = (index) => {
        const updatedMobiles = additionalMobiles.filter((_, i) => i !== index);
        setAdditionalMobiles(updatedMobiles);
    };

    const renderProgressBar = () => {
        const steps = Array(5).fill(null); // Create an array of 5 steps
        return (
            <div className="tube-progress-bar">
                {steps.map((_, index) => (
                    <div key={index} className={`tube-progress-step ${index <= step ? 'active' : ''}`}></div>
                ))}
            </div>
        );
    };
    const formData = {
        businessDetails,
        contactDetails,
        businessTiming,
        paymentDetails,
        servicesDetails,
        additionalMobiles,
        additionalWhatsapps,
        additionalLandlines,
        additionalEmails,
        additionalServices
    };
    // console.log(selectedCategory, selectedSubCategory);


    const handlePlaceSelect = () => {
        // Check if autocompleteRef.current is initialized
        if (autocompleteRef.current) {
            const place = autocompleteRef.current.getPlace();
            if (!place || !place.address_components) return;

            const addressComponents = place.address_components;
            const getAddressComponent = (type) => {
                const component = addressComponents.find((comp) => comp.types.includes(type));
                return component ? component.long_name : '';
            };
            console.log(place.formatted_address)
            setGoogleAddress(place.formatted_address);
        }
    };

    const handlePlaceSelect1 = () => {
        if (autocompleteRef1.current) {
            const place = autocompleteRef1.current.getPlace();
            if (!place || !place.address_components) return;

            const addressComponents = place.address_components;

            const getAddressComponent = (type) => {
                const component = addressComponents.find((comp) => comp.types.includes(type));
                return component ? component.long_name : '';
            };

            const city = getAddressComponent('locality');
            const pinCode = getAddressComponent('postal_code');
            console.log(city, pinCode)
            // If city and pin code are found, add them to the cities array
            if (city) {
                setCities(prevCities => [...prevCities, { city, pinCode }]);
                setGoogleAddresss(''); // Clear the input after adding
            }
        }
    };

    const handleDeleteCity = (index) => {
        setCities(prevCities => prevCities.filter((_, i) => i !== index));
    };

    console.log(store)

    const storedoneornot = async () => {
        try {
            const response = await fetch(`https://100billionoms.com/store/getstorelist/mystore/${login.permanent_ID}`)
            const res = await response.json();
            console.log(res);
            if (response.ok) {
                setStore(true);
            } else if (response.status === 400) {
                setStore(false);
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchDataCategory();
        storedoneornot();
        getPlan();
        onOpen();
        openModal2();
    }, [])

    let handleDetect = () => {
        if (!navigator.geolocation) {
            // Geolocation is not supported by the browser
            console.log("Geolocation is not supported by your browser.");
            return;
        }

        // Try to get the current position

        navigator.geolocation.getCurrentPosition(
            (position) => {
                console.log(position.coords);
                setSelectedLocation({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                })

                const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&sensor=false&key=AIzaSyD0AcxoBWhqkMIAfK-F-X6ya3Es-52o1YU`;
                fetch(url)
                    .then((res) => res.json())
                    .then((res) => {
                        console.log(res);
                        setVendorLocation(res.results[0].formatted_address)
                        setGoogleAddress(res.results[0].formatted_address);
                        // setAddress1(res.results[0].formatted_address)
                        // onQueryChange(res.results[0].formatted_address)
                        onClose();
                        if (store) {
                            toast({
                                description: "Store is Registered already",
                                status: 'warning',
                                duration: 9000,
                                position: 'top-center',
                                isClosable: true,
                            });
                        }
                    })
                    .catch((error) => {
                        console.log(error);

                    });
            },
            () => {
                // If the user denied geolocation access
                console.log("Please allow geolocation access to detect your location.");

            }
        );
    };

    // Function to handle sharing





    // console.log(selectedLocation, vendor_location);

    if (!isLoaded) return <p>Loading...</p>;
    const renderStepContent = () => {
        switch (step) {
            case 0:
                return (
                    <div className="form-step">
                        <h2>Enter Your Store Details</h2>
                        <input type="text" name="businessName" placeholder="Store Name" value={businessDetails.businessName} onChange={handleBusinessDetailsChange} />

                        <input type="text" name="buildingName" placeholder="Building Name" value={businessDetails.buildingName} onChange={handleBusinessDetailsChange} />
                        <div className='city'>
                            <input type="text" name="street" placeholder="Street" value={businessDetails.street} onChange={handleBusinessDetailsChange} />
                            <input type="text" name="landmark" placeholder="Landmark" value={businessDetails.landmark} onChange={handleBusinessDetailsChange} />
                        </div>
                        <div className='city'>
                            <input type="text" name="area" placeholder="Area" value={businessDetails.area} onChange={handleBusinessDetailsChange} />
                            <input type="text" name="pincode" placeholder="Pincode" value={businessDetails.pincode} onChange={handleBusinessDetailsChange} />
                        </div>
                        <div className='city'>
                            <input type="text" name="city" placeholder="City" value={businessDetails.city} onChange={handleBusinessDetailsChange} />
                            <input type="text" name="state" placeholder="State" value={businessDetails.state} onChange={handleBusinessDetailsChange} />
                        </div>
                        <Autocomplete
                            onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
                            onPlaceChanged={handlePlaceSelect}
                        >
                            <input type="text"
                                placeholder="Type & Select Store Address (Google)"
                                value={google_address}
                                onChange={(e) => setGoogleAddress(e.target.value)} />
                        </Autocomplete>
                        <input type="text" name="description" placeholder="Store Description" value={businessDetails.description} onChange={handleBusinessDetailsChange} />
                        {/* <div>
                            <label className='businessSince'>Delivery Availability (City):</label>
                            <Autocomplete
                                onLoad={(autocomplete) => (autocompleteRef1.current = autocomplete)}
                                onPlaceChanged={handlePlaceSelect1}
                            >
                                <input
                                    type="text"
                                    placeholder="Enter City and Pin Code"
                                    value={googleAddress}
                                    onChange={(e) => setGoogleAddresss(e.target.value)}
                                />
                            </Autocomplete>

                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '0px', flexWrap: "wrap", gap: "5px" }}>
                                {cities.map((item, index) => (
                                    <div key={index} style={{ display: 'flex', alignItems: 'center', marginTop: '0px', flexWrap: "wrap", border: "1px solid red", padding: "5px", borderRadius: "5px" }}>
                                        <span style={{ fontSize: "12px" }}>{item.city}<button
                                            className='delete-citiesall'
                                            style={{ marginLeft: '2px', cursor: 'pointer' }}
                                            onClick={() => handleDeleteCity(index)}
                                        >
                                            <CloseIcon boxSize={2} />
                                        </button></span>

                                    </div>
                                ))}
                            </div>
                        </div> */}
                        <div>
                            <label className='busniesssince'>In Business Since:</label>
                            <input type="date" name="businessDate" placeholder="businessDate" value={businessDate} onChange={(e) => setBusinessdate(e.target.value)} />
                        </div>
                        <div className="listing-form-buttons">
                            <button className='listing-save-button' onClick={handleNextStep}>Save and Continue</button>

                        </div>

                    </div>
                );
            case 1:
                return (
                    <div className="form-step">
                        <h2>Add Contact Details</h2>
                        <div className='person'>
                            <select name="title" value={contactDetails.title} onChange={handleContactDetailsChange} className="title">
                                <option value="" disabled selected>Title</option>
                                <option value="Mr">Mr</option>
                                <option value="Mrs">Mrs</option>
                                <option value="Ms">Ms</option>
                            </select>
                            <input type="text" name="personName" value={contactDetails.personName} placeholder="Person Name" onChange={handleContactDetailsChange} className='person-name' />
                        </div>
                        <div className='person-number'>
                            <div className='contact91'>+91</div>
                            <input type="text" name="mobile" value={contactDetails.mobile} placeholder="Mobile Number" onChange={handleContactDetailsChange} />
                        </div>
                        {additionalMobiles.map((mobile, index) => (
                            <div key={index} className="person-number">
                                <div className="contact91">+91</div>
                                <input
                                    type="number"
                                    value={mobile}
                                    onChange={(e) => handleAdditionalMobileChange(index, e)}
                                    placeholder={`Additional Mobile ${index + 1}`}
                                />
                                <button onClick={() => removeMobileField(index)} className="close-button">×</button>
                            </div>
                        ))}
                        <button className='add-more-listing-button listing-add-button' onClick={addNewMobileField}>
                            + Add Another Mobile Number
                        </button>

                        <div className='person-number'>
                            <div className='contact91'>+91</div>
                            <input type="number" name="whatsapp" value={contactDetails.whatsapp} placeholder="WhatsApp Number" onChange={handleContactDetailsChange} />
                        </div>
                        {additionalWhatsapps.map((whatsapp, index) => (
                            <div key={index} className="person-number">
                                <div className="contact91">+91</div>
                                <input
                                    type="text"
                                    value={whatsapp}
                                    onChange={(e) => handleAdditionalWhatsappChange(index, e)}
                                    placeholder={`Additional WhatsApp ${index + 1}`}
                                />
                            </div>
                        ))}
                        <button className='add-more-listing-button listing-add-button' onClick={addNewWhatsAppField}>
                            + Add Another WhatsApp Number
                        </button>
                        <br /> <br />
                        <button id='landline-plus-button' className='listing-add-button' onClick={addNewLandlineField}>
                            + Add Another Landline Number
                        </button>
                        {additionalLandlines.map((landline, index) => (
                            <input
                                key={index}
                                type="text"
                                value={landline}
                                onChange={(e) => handleAdditionalLandlineChange(index, e)}
                                placeholder={`Additional Landline ${index + 1}`}
                            />
                        ))}


                        <input type="email" name="email" value={contactDetails.email} placeholder="Email ID" onChange={handleContactDetailsChange} />
                        {additionalEmails.map((email, index) => (
                            <input
                                key={index}
                                type="email"
                                value={email}
                                onChange={(e) => handleAdditionalEmailChange(index, e)}
                                placeholder={`Additional Email ${index + 1}`}
                            />
                        ))}
                        <button id='email-plus-button' className='listing-add-button' onClick={addNewEmailField}>
                            + Add Another Email Address
                        </button>

                        <br /><br />
                        <div className="listing-form-buttons">
                            <button className='listing-save-button' onClick={handleNextStep}>Save and Continue</button>
                            <button className='listing-skip-button' onClick={handlePrevStep}>Previous</button>
                        </div>

                    </div>
                );
            case 2:
                return (
                    <div className="form-step">

                        <h2>Add Store Timing</h2>
                        <p className='daysofweek-p'>Let your customer know when you are open for store</p>
                        {/* <div className="days-of-week">
                            {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
                                <label key={day} className={businessTiming.selectedDays[day] ? 'selected-day' : ''}>
                                    <input
                                        type="checkbox"
                                        checked={businessTiming.selectedDays[day] || false}
                                        onChange={() => handleCheckboxChange(day)}
                                    />
                                    {day}
                                </label>
                            ))}
                        </div> */}


                        {/* <div className='days-week-select-all'>
                            <button className='select-all-button' onClick={handleSelectAllDays}>
                                {Object.keys(businessTiming.selectedDays).length === 7 && Object.values(businessTiming.selectedDays).every(Boolean) ? 'Deselect All Days' : 'Select All Days'}
                            </button>
                        </div> */}

                        <div className='time'>
                            {renderTimeSlots()}

                            {/* <button className="add-more-listing-button listing-add-button" onClick={handleAddTimeSlot}>
    + Add Another Time Slot
</button> */}
                            {/* {businessTiming.timeSlots.map((slot, index) => (
                                <div key={index} className='time-slot'>
                                    <div className='time-slot-list'>
                                        <select name="openTime" value={slot.openTime} onChange={(e) => handleTimeSlotChange(index, e)}>
                                            <option value="">Select Open Time</option>
                                            {Array.from({ length: 24 }, (_, i) => (
                                                <option value={`${i < 10 ? '0' : ''}${i}:00`}>{`${i < 10 ? '0' : ''}${i}:00`}</option>
                                            ))}
                                        </select>
                                        <select name="closeTime" value={slot.closeTime} onChange={(e) => handleTimeSlotChange(index, e)}>
                                            <option value="">Select Close Time</option>
                                            {Array.from({ length: 24 }, (_, i) => (
                                                <option value={`${i < 10 ? '0' : ''}${i}:00`}>{`${i < 10 ? '0' : ''}${i}:00`}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            ))} */}
                        </div>
                        <button className='add-more-listing-button listing-add-button' onClick={handleAddTimeSlot}>
                            + Add Another Time Slot
                        </button>
                        <br /><br />

                        <div className="listing-form-buttons">
                            <button className='listing-save-button' onClick={handleNextStep}>Save and Continue</button>
                            <button className='listing-skip-button' onClick={handlePrevStep}>Previous</button>
                        </div>
                    </div>
                );
            case 3:
                return (
                    <div className="form-step">
                        <h2>Add Your Services & Category</h2>
                        <input type="text" name="service" value={servicesDetails.service} placeholder="Service " onChange={handleServicesDetailsChange} />
                        {additionalServices.map((service, index) => (
                            <input
                                key={index}
                                type="text"
                                value={service}
                                onChange={(e) => handleAdditionalServicesChange(index, e)}
                                placeholder={`Additional Service ${index + 1}`}
                            />
                        ))}
                        <button id='email-plus-button' className='listing-add-button' onClick={addNewServicesField}>
                            + Add Another Service
                        </button>

                        <br /><br />
                        {/* Category Dropdown */}
                        <Select onChange={handleCategoryChange} value={selectedCategory} placeholder="Select Category">

                            {categories.map((category) => (
                                <option key={category.id} value={category.category_name}>
                                    {category.category_name}
                                </option>
                            ))}
                        </Select>

                        {/* Subcategory Dropdown (if any) */}
                        {subCategories.length > 0 && (
                            <Select onChange={handleSubCategoryChange} value={selectedSubCategory} placeholder="Select Subcategory">

                                {subCategories.map((subCategory) => (
                                    <option key={subCategory.id} value={subCategory.subcategory_name}>
                                        {subCategory.subcategory_name}
                                    </option>
                                ))}
                            </Select>
                        )}

                        <br />
                        <Text size="sm" mt={0}>
                            Do you have GST?
                        </Text>
                        <Select
                            placeholder="Select Option"
                            onChange={(e) => {
                                const value = e.target.value;
                                setGst({
                                    ...gst,
                                    gstAvailable: value,
                                    gstNumber: value === "Yes" ? gst.gstNumber || "" : "", // Clear GST number if "No" is selected
                                });
                            }}
                            value={gst.gstAvailable}
                        >
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </Select>

                        {gst.gstAvailable === "Yes" && (
                            <input
                                type="text"
                                name="gstNumber"
                                value={gst.gstNumber || ""}
                                placeholder="Enter GST Number"
                                onChange={(e) =>
                                    setGst({
                                        ...gst,
                                        gstNumber: e.target.value,
                                    })
                                }
                            />
                        )}
                        <div className="listing-form-buttons">
                            <button className='listing-save-button' onClick={handleNextStep}>Save and Continue</button>
                            <button className='listing-skip-button' onClick={handlePrevStep}>Previous</button>
                        </div>

                    </div>
                );
            case 4:
                return (
                    <div className="form-step">
                        <h2>Add Photos</h2>
                        <ImageUpload onImageUpload={handleFormImageUpload} />;
                        <div className="listing-form-buttons">
                            <button className='listing-save-button' onClick={handleNextStep}>Save and Continue</button>
                            <button className='listing-skip-button' onClick={handlePrevStep}>Previous</button>
                        </div>
                    </div>
                );



            case 5:
                return (
                    <div className="form-step">
                        <h2>Payment Details</h2>
                        <input type="text" name="beneficiaryName" value={paymentDetails.beneficiaryName} placeholder="Beneficiary Name" onChange={handlePaymentDetailsChange} />
                        <input
                            type={focusedField === 'accountNumber' ? 'text' : 'password'} // Show text when focused, otherwise hide
                            name="accountNumber"
                            value={paymentDetails.accountNumber}
                            placeholder="Bank Account Number"
                            onFocus={() => setFocusedField('accountNumber')}
                            onBlur={() => setFocusedField('')}
                            onChange={handlePaymentDetailsChange}
                        />
                        <input type="text" name="confirmaccountNumber" value={paymentDetails.confirmaccountNumber} placeholder="Confirm Bank Account Number" onChange={handlePaymentDetailsChange} />
                        <input type="text" name="bankName" value={paymentDetails.bankName} placeholder="Bank Name" onChange={handlePaymentDetailsChange} />
                        <input type="text" name="ifscCode" value={paymentDetails.ifscCode} placeholder="IFSC Code" onChange={handlePaymentDetailsChange} />

                        <div className="listing-form-buttons">
                            {
                                load ?
                                    <button className='listing-save-button' onClick={handlePayment}>Submit</button> :
                                    <button className='listing-save-button' ><Spinner /></button>
                            }

                            <button className='listing-skip-button' onClick={handlePrevStep}>Previous</button>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <>
            <Header />
            <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
                <ModalOverlay />
                <ModalContent>
                    {/* <ModalHeader>Modal Title</ModalHeader> */}
                    {/* <ModalCloseButton /> */}
                    <ModalBody>
                        <Text textAlign={"center"} mt={"30px"}>Are you at your store location?</Text>
                        <Box mt={"10px"}>
                            <Flex textAlign={"center"} justifyContent={"center"}>
                                <Button mr={3} bg={"#0be10b"} color={"white"} _hover={{ background: "#04b904", color: "white", fontWeight: "600" }} onClick={handleDetect}>Yes</Button>
                                <Button bg={"red"} color={"white"} _hover={{ background: "#d30507", color: "white", fontWeight: "600" }} onClick={openModal1}>No</Button>
                            </Flex>
                        </Box>
                    </ModalBody>
                    <ModalFooter>

                    </ModalFooter>

                </ModalContent>
            </Modal>
            <Modal isOpen={isModal2Open} closeOnOverlayClick={false} size={{ base: "full", sm: "full", md: "full", lg: "5xl", xl: "5xl" }} onClose={closeModal2}
                scrollBehavior="inside"
            >
                <ModalOverlay />
                <ModalContent>
                    {/* <ModalHeader>Modal Title</ModalHeader> */}
                    <Box position="absolute" top={2} right={4}>
                        <CloseIcon onClick={() => navigate(-1)} cursor="pointer" />
                    </Box>
                    <ModalBody>
                        <Box bg="red.50" p={"15px"}>
                            <VStack spacing={4} mb={10}>
                                <Heading color="red.600">Plans</Heading>
                                <Text textAlign="center" maxW="600px" color="gray.600">
                                    To Register your store you need to pay the amount below and after that will get listed in bigbullpaisewala store
                                </Text>
                                {/* <HStack spacing={4}>
                                    <Text fontWeight="bold" color="red.600">
                                        MONTHLY
                                    </Text>
                                    <Switch colorScheme="red" />
                                    <Text fontWeight="bold" color="gray.500">
                                        YEARLY
                                    </Text>
                                </HStack> */}
                            </VStack>

                            <Flex wrap="wrap" justify="center" gap={6}>
                                {plan.map((plan, index) => (
                                    <Box
                                        key={index}
                                        bg="white"
                                        shadow="xl"
                                        borderRadius="lg"
                                        p={6}
                                        w="280px"
                                        textAlign="center"
                                        border={planobject.plan_name === plan.plan_name ? "2px solid" : "1px solid"}
                                        borderColor={planobject.plan_name === plan.plan_name ? "red.500" : "gray.200"}
                                    >
                                        <Badge
                                            colorScheme="red"
                                            variant="solid"
                                            borderRadius="full"
                                            px={3}
                                            py={1}
                                            mb={4}
                                            fontSize="sm"
                                            alignSelf="center"
                                        >
                                            {plan.plan_name}
                                        </Badge>
                                        <Heading color="red.600" fontSize="4xl">
                                            ₹{plan.sale_price}
                                            <Text as="span" textDecoration={"line-through"} fontSize="lg" ml={"5px"} color="gray.600">
                                                ₹{plan.regular_price}
                                            </Text>
                                        </Heading>
                                        <Text as="span" fontSize="md" ml={"5px"} color="gray.600">
                                            {plan.validity}days
                                        </Text>
                                        <Divider my={6} borderColor="gray.300" />
                                        <VStack spacing={3} mb={6}>
                                            <Text as="span" fontSize="lg" color="gray.600">
                                                {plan.description}
                                            </Text>
                                        </VStack>
                                        <Button
                                            bg={planobject.plan_name === plan.plan_name ? "red.600" : "red"}
                                            color={"white"}
                                            size="lg" w="full"
                                            onClick={() => {
                                                setPlanObject(plan); // Set the selected plan
                                                closeModal2(); // Close the modal
                                            }}
                                            _hover={{ bg: planobject.plan_name === plan.plan_name ? "red.600" : "red" }}
                                        >
                                            {planobject.plan_name === plan.plan_name ? "SELECTED" : "SELECT"}
                                        </Button>
                                    </Box>
                                ))}
                            </Flex>
                        </Box>
                    </ModalBody>
                    {/* <ModalFooter>

                    </ModalFooter> */}

                </ModalContent>
            </Modal>
            <Modal isOpen={isModal1Open} onClose={closeModal1} closeOnOverlayClick={false}>
                <ModalOverlay />
                <ModalContent>
                    {/* <ModalHeader>Modal Title</ModalHeader> */}
                    <ModalCloseButton />
                    <ModalBody>
                        <Text textAlign={"center"} mt={"30px"}>If you are not close to your store, please don't proceed elsewhere to avoid issues with delivery partners.</Text>
                        <Button display={"block"} margin={"auto"} mt={3} bg={"red"} color={"white"} _hover={{ background: "#d30507", color: "white", fontWeight: "600" }}>Home</Button>
                    </ModalBody>
                    <ModalFooter>

                    </ModalFooter>

                </ModalContent>
            </Modal>
            <div className="listing-form">
                <div className="listing-form-container">
                    <div className="listing-form-image">
                        <img src={vendorFormImage} alt="Listing" />
                    </div>
                    <div className="listing-form-content">
                        {renderProgressBar()}
                        {renderStepContent()}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ListingForm;
