import React, { useState } from "react";
import '../CSS/Global.css';

const ChatUI = ({ isChatOpen, setIsChatOpen }) => {
  const [activeMessage, setActiveMessage] = useState(null);
  const [inputMessage, setInputMessage] = useState("");

  const messages = [
    { id: 1, name: "Jean Philippe A.", snippet: "Coders can earn up to $10 per hour", unread: 3 },
    { id: 2, name: "The Executive Centre", snippet: "Premium Mumbai Office Spaces Unveiled", unread: 1 },
    { id: 3, name: "Pratik Kapasi", snippet: "Your unique experience is a fit for Amazon", unread: 0 },
    { id: 4, name: "LinkedIn Offer", snippet: "Hi Kunal! Are you interested in...", unread: 2 },
    { id: 5, name: "Atul Sharma", snippet: "Hi Kunal, it's great to connect with you...", unread: 0 },
  ];

  const chatMessages = {
    1: [
      { id: 1, sender: "you", message: "Hi Jean, I'm interested in your offer!", time: "1:05 PM" },
      { id: 2, sender: "Jean", message: "Great! I can provide more details.", time: "1:06 PM" },
    ],
    2: [
      { id: 1, sender: "you", message: "Hi! Can I know more about your office spaces?", time: "2:00 PM" },
      { id: 2, sender: "Executive Centre", message: "Sure! We have various options available.", time: "2:01 PM" },
    ],
    3: [
      { id: 1, sender: "you", message: "Hello Pratik, what is the unique experience?", time: "3:00 PM" },
      { id: 2, sender: "Pratik", message: "It's tailored for each candidate.", time: "3:01 PM" },
    ],
    4: [
      { id: 1, sender: "you", message: "Interested in the LinkedIn offer!", time: "4:00 PM" },
      { id: 2, sender: "LinkedIn", message: "Great! Let's discuss further.", time: "4:01 PM" },
    ],
    5: [
      { id: 1, sender: "you", message: "Hi Atul! Let's connect!", time: "5:00 PM" },
      { id: 2, sender: "Atul", message: "Sure, looking forward to it.", time: "5:01 PM" },
    ],
  };

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
    setActiveMessage(null);
  };

  const openMessage = (message) => {
    setActiveMessage(message.id);
  };

  const goBackToMessages = () => {
    setActiveMessage(null);
    setInputMessage("");
  };

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (inputMessage.trim() !== "") {
      console.log(`Sent: ${inputMessage}`);
      setInputMessage("");
    }
  };

  return (
    <div className={`chat-container ${isChatOpen ? "open" : ""}`}>
      <div className="header-chat" onClick={toggleChat}>
        <div className="title">Messaging</div>
        <i className={`fa fa-chevron-${isChatOpen ? "up" : "down"} chevron-icon`}></i>
      </div>

      {isChatOpen && (
        <div className="chat-body">
          {!activeMessage ? (
            <>
              <div className="search-bar">
                <input type="text" placeholder="Search messages" />
              </div>

              <div className="message-list">
                {messages.map((message) => (
                  <div
                    key={message.id}
                    className={`message-item ${message.unread ? "unread" : ""}`}
                    onClick={() => openMessage(message)}
                  >
                    <div className="avatar"></div>
                    <div className="message-info">
                      <div className="message-header">
                        <span className="message-name">{message.name}</span>
                      </div>
                      <div className="message-snippet">{message.snippet}</div>
                    </div>
                    {message.unread > 0 && (
                      <div className="unread-badge">{message.unread}</div>
                    )}
                  </div>
                ))}
              </div>
            </>
          ) : (
            <div className="chat-details">
              <button className="back-button" onClick={goBackToMessages}>
                <i className="fa fa-arrow-left"></i> Back
              </button>
              <div className="chat-header">
                <span className="sender-name">{messages.find(m => m.id === activeMessage).name}</span>
              </div>
              <div className="chat-messages">
                {chatMessages[activeMessage].map((chat) => (
                  <div key={chat.id} className={`chat-message ${chat.sender === "you" ? "my-message" : "their-message"}`}>
                    <div className="chat-text">{chat.message}</div>
                    <span className="chat-time">{chat.time}</span>
                  </div>
                ))}
              </div>

              <form className="message-input" onSubmit={handleSendMessage}>
                <input
                  type="text"
                  value={inputMessage}
                  onChange={(e) => setInputMessage(e.target.value)}
                  placeholder="Type a message..."
                />
                <button type="submit">Send</button>
              </form>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ChatUI;
