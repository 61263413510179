import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../CSS/Global.css';
import { useNavigate } from 'react-router-dom';

const SearchPage = () => {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]); // Store search results
    const navigate = useNavigate()

    const handleSearchChange = (event) => {
        setQuery(event.target.value);
    };

    useEffect(() => {
        // Fetch stores whenever the query changes
        const fetchStores = async () => {
            try {
                const response = await axios.get(`https://100billionoms.com/store/getstore/search`, {
                    params: { name: query },
                });

                // console.log(response);
                const parsedResults = response.data.map((store) => {
                    return {
                        ...store,
                        additionalEmails: JSON.parse(store.additionalEmails || "[]"),
                        additionalLandlines: JSON.parse(store.additionalLandlines || "[]"),
                        additionalMobiles: JSON.parse(store.additionalMobiles || "[]"),
                        additionalServices: JSON.parse(store.additionalServices || "[]"),
                        additionalWhatsapps: JSON.parse(store.additionalWhatsapps || "[]"),
                        businessDetails: JSON.parse(store.businessDetails || "{}"),
                        businessTiming: JSON.parse(store.businessTiming || "{}"),
                        contactDetails: JSON.parse(store.contactDetails || "{}"),
                        formImages: JSON.parse(store.formImages || "[]"),
                        paymentDetails: JSON.parse(store.paymentDetails || "{}"),
                        servicesDetails: JSON.parse(store.servicesDetails || "{}"),
                    };
                });
                console.log(parsedResults);
                setResults(parsedResults);
            } catch (error) {
                console.error("Error fetching stores:", error);
            }
        };
        if (query) {
            fetchStores();
        } else {
            setResults([]); // Clear results if query is empty
        }
    }, [query]);

    return (
        <div className="search-page">
            <input
                type="text"
                placeholder="Search for store..."
                className="search-page__input"
                value={query}
                onChange={handleSearchChange}
            />

            <div className="search-page__results">
                {results.length > 0 ? (
                    results.map((result, index) => (
                        <div key={index} className='searchresultbacktoback' onClick={() => navigate(`/detail-listing/${result.id}/${result.businessDetails.businessName}`)}>
                            <div>
                                <img className='search-imagebacktoback' src={`https://100billionoms.com/${result.formImages[0]}`} />
                            </div>
                            <div>
                                <h1 className='businessdetailsbacktoback'>{result.businessDetails.businessName}</h1>
                                <p className='businessDetailscityptag'>{result.businessDetails.city}</p>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>No results found</p>
                )}
            </div>
        </div>
    );
};

export default SearchPage;
