import '../CSS/Global.css';

const Footer = () => {
    const handleLink = () => {
        window.open('https://100billiontech.com/', '_blank'); // Opens the link in a new tab
    };

    return (
        <footer className="footer">
            <h3 className="footer-title">
                Made with <i className="fa-solid fa-heart"></i>, Connecting Vendors and Users
            </h3>
            <p className="footer-text">
                Powered By{' '}
                <span className="footer-link" onClick={handleLink}>
                    100Billion Tech
                </span>
            </p>
        </footer>
    );
};

export default Footer;
