import React, { useState } from 'react'; 
import './Table.css';  // Importing the CSS file

const ProductListing = () => {
  // Sample product data
  const productData = [
    {
      productName: 'Wireless Headphones',
      image: 'product1.jpg',
      mrp: '₹4,999',
      sellingPrice: '₹3,499',
      shortDescription: 'Noise-canceling headphones',
    },
    {
      productName: 'Smart Watch',
      image: 'product2.jpg',
      mrp: '₹6,999',
      sellingPrice: '₹4,999',
      shortDescription: 'Track your fitness',
    },
    {
      productName: 'Bluetooth Speaker',
      image: 'product3.jpg',
      mrp: '₹3,499',
      sellingPrice: '₹2,199',
      shortDescription: 'Portable Bluetooth speaker',
    },
    {
      productName: 'Gaming Mouse',
      image: 'product4.jpg',
      mrp: '₹2,499',
      sellingPrice: '₹1,499',
      shortDescription: 'High precision gaming mouse',
    },
    {
      productName: '4K Ultra HD TV',
      image: 'product5.jpg',
      mrp: '₹50,000',
      sellingPrice: '₹39,999',
      shortDescription: 'Smart TV with HDR',
    },
  ];
  
  const [searchTerm, setSearchTerm] = useState('');

  const filteredProducts = productData.filter(product =>
    product.productName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="table-container">
      <h2>Product Listing</h2>
      <div className="search-container">
        <input
          type="text"
          placeholder="Search by product name..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="table-wrapper">
        <table className="bigbull-table">
          <thead>
            <tr>
              <th>Product Name</th>
              <th>Product Image</th>
              <th>MRP</th>
              <th>Selling Price</th>
              <th>Short Description</th>
            </tr>
          </thead>
          <tbody>
            {filteredProducts.map((product, index) => (
              <tr key={index}>
                <td>{product.productName}</td>
                <td><img src={product.image} alt={product.productName} width="50" /></td>
                <td>{product.mrp}</td>
                <td>{product.sellingPrice}</td>
                <td>{product.shortDescription}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProductListing;
