// src/components/Home.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../CSS/Global.css';
import Ads from './Ads';
import Vendor from './Vendor';
import CategoryCol from './CategoryCol';
import CategoryGrid from './CategoryGrid'; // Import the new component
import main1 from '../Images/main2.avif';
import main2 from '../Images/main1.avif';
import main3 from '../Images/main3.jpeg';
import main4 from '../Images/main4.avif';
import main5 from '../Images/main5.avif';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import Header from './Header';
import Footer from './Footer';

const Home = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [images, setImages] = useState([])
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [amount, setAmount] = useState("");
  const navigate = useNavigate();
  //  const login = JSON.parse(localStorage.getItem("login")) || ""
  // const images = [main1, main2, main3, main4, main5];
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    if (images.length > 0 && !isPaused) { // Only start the slider if there are images
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 6000);

      return () => clearInterval(interval); // Cleanup on unmount or images change
    }
  }, [images.length, isPaused]); // Add images.length as a dependency

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  const fetchSliderdata = async () => {
    try {
      const response = await fetch(`https://100billionoms.com/slider/getslider/user`)
      const res = await response.json();
      const activeImages = res.filter(image => image.status === 'Active')
        .sort((a, b) => a.position - b.position);
      setImages(activeImages);
    } catch (error) {
      console.log(error);
    }
  }

  const [userData, setUserData] = useState(null);
  const login = JSON.parse(localStorage.getItem("login")) || ""
  const [isPaid, setIsPaid] = useState(false);
  // Decrypt function (mirrors backend decryption)
  const decrypt = (encryptedData, iv) => {
    const key = 'ThisIsA32CharacterLongKey123456!'; // 32-character key used on the backend

    // Convert key, IV, and encrypted data to appropriate CryptoJS formats
    const keyHex = CryptoJS.enc.Utf8.parse(key);  // Convert the key to a CryptoJS format
    const ivHex = CryptoJS.enc.Hex.parse(iv);     // Convert IV from 'hex'

    // Decrypt the data using AES with CBC mode
    const decrypted = CryptoJS.AES.decrypt({ ciphertext: CryptoJS.enc.Hex.parse(encryptedData) }, keyHex, {
      iv: ivHex,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    // Return the decrypted UTF-8 string
    return decrypted.toString(CryptoJS.enc.Utf8);
  };


  // Fetch encrypted user data from the backend
  const fetchUserData = async () => {
    try {
      const response = await axios.get(`https://100billionoms.com/payments/users/${login.permanent_ID}`);
      const encryptedData = response.data.data;

      console.log("Encrypted Data from Backend:", encryptedData.encryptedData);
      console.log("IV from Backend:", encryptedData.iv);

      // Decrypt the data
      const decryptedData = decrypt(encryptedData.encryptedData, encryptedData.iv);

      console.log("Decrypted Data:", decryptedData);  // Should contain the decrypted JSON string

      // Ensure that the decrypted data is valid before parsing
      if (decryptedData) {
        const parsedData = JSON.parse(decryptedData);
        setUserData(parsedData); // Set the user data

        // Check if any object's status is 'Paid'
        const hasPaidStatus = parsedData.some(user => user.status === 'Paid');
        setIsPaid(hasPaidStatus); // Set the isPaid state

        // Open modal if not paid
        if (!hasPaidStatus && login !== "") {
          onOpen(); // Open the modal if no status is 'Paid'
        }
      } else {
        console.error("Decrypted data is empty or invalid");
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  // Function to simulate payment confirmation
  const confirmPayment = () => {
    setIsPaid(true); // Set isPaid to true when payment is confirmed
    onClose(); // Close the modal
  };



  const fetchDataPrice = async () => {
    try {
      const response = await fetch(`https://100billionoms.com/registerpay`);
      const res = await response.json();
      setAmount(res.data[0].register_price);
    } catch (error) {
      console.log(error);
    }
  };

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false)
      }
      document.body.appendChild(script);
    })
  };

  const handleClick = async (log) => {
    try {
      let orderId = "11" + Math.floor(Math.random() * Math.floor(Math.random() * Date.now()));

      // Load the Razorpay SDK
      let res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

      if (!res) {
        alert("Razorpay SDK Failed to load. Are you online?");
        return;
      }

      // Define payment details
      let paymentDetails = {

        user_id: login.permanent_ID,
        user_phone: login.name,
        transaction_id: orderId,
        amount: amount * 100,
        currency: "INR",
        payment_capture: 1,
        payment_mode: "online",
      };

      // Create order options
      let options = {
        key: "rzp_test_zEWr4dOpfx4w2F",
        currency: paymentDetails.currency,
        amount: paymentDetails.amount,
        name: "BigBull Paisewala",
        description: "Test Transaction",
        handler: async function (response) {

          if (response.razorpay_payment_id) {
            // Payment was successful
            paymentDetails.razorpay_payment_id = response.razorpay_payment_id;
            paymentDetails.status = 'Paid';
            let result = await axios.post(`https://100billionoms.com/payments`, paymentDetails);

            if (!result.data.isError) {
              // Redirect to the success page
              //   checkPaymentStatus();
              onClose();
              fetchUserData();
              window.location.reload();

              // window.location.href = `/delivery/${orderId}`; // Replace "/success-page" with the URL of your success page
            } else {
              alert("Server error. Are You Online");
              //   window.location.href = "/error-page"; // Redirect to an error page
            }
          } else {
            // Handle payment failure or cancellation

            paymentDetails.status = 'Failed';
            await axios.post(`https://100billionoms.com/payments`, paymentDetails);
            navigate("/")
            // window.location.href = "/error-page"; // Redirect to an error page
          }
        },
        prefill: {
          email: "pankajsbisht143@gmail.com",
          contact: "9021733853"
        },
        notes: {
          address: "Razorpay Corporate Office"
        },
        theme: {
          color: "#1f5215"
        }
      };

      // Initialize Razorpay payment object and open checkout
      let paymentObject = new window.Razorpay(options);
      paymentObject.on('payment.failed', function (response) {
        paymentDetails.status = 'Failed';
        axios.post(`https://100billionoms.com/payments`, paymentDetails)
          .then(() => {
            navigate("/") // Redirect to an error page
          }).catch(error => {
            navigate("/") // Redirect to an error page
          });
      });

      paymentObject.open();

    } catch (error) {
      console.log(error);
    }
  };

  const [touchStartX, setTouchStartX] = useState(0); // Track starting X position of the touch
  const [touchEndX, setTouchEndX] = useState(0);    // Track ending X position of the touch
 


  const handleTouchStart = (e) => {
    setIsPaused(true);
    setTouchStartX(e.touches[0].clientX); // Store starting X coordinate
  };

  const handleTouchMove = (e) => {
   
    setTouchEndX(e.touches[0].clientX); // Continuously update ending X coordinate
  };

  const handleTouchEnd = () => {
    setIsPaused(false);
    const swipeDistance = touchStartX - touchEndX;

    // Define minimum swipe distance to trigger slide change
    const minSwipeDistance = 50;

    if (swipeDistance > minSwipeDistance) {
      // Swipe left (Next slide)
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    } else if (swipeDistance < -minSwipeDistance) {
      // Swipe right (Previous slide)
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? images.length - 1 : prevIndex - 1
      );
    }
  };

  useEffect(() => {
    if (images.length > 0 && !isPaused) { // Only start the slider if there are images
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 6000);

      return () => clearInterval(interval); // Cleanup on unmount or images change
    }
  }, [images.length, isPaused]);

  // Fetch data on component mount
  useEffect(() => {
    fetchUserData();
    fetchDataPrice();
  }, []);

  useEffect(() => {
    fetchSliderdata();
  }, [])

  return (
    <div>
      <Header />
      {/* Slider Section */}
      <div className="slider">
        <div className="slider__container"
          onTouchStart={(e) => handleTouchStart(e)}
          onTouchMove={(e) => handleTouchMove(e)}
          onTouchEnd={handleTouchEnd}
        >
          <div
            className="slider__images"
            style={{ transform: `translateX(-${currentIndex * 100}%)`, transition: 'transform 0.5s ease' }}
            // style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          >
            {images.map((image, index) => (
              <div key={index} className="slider__item">
                <img src={`https://100billionoms.com/${image.image}`} alt={`Slide ${index + 1}`} />
              </div>
            ))}
          </div>
        </div>
        <div className="slider__pagination">
          {images.map((_, index) => (
            <span
              key={index}
              className={`slider__dot ${currentIndex === index ? 'active' : ''}`}
              onClick={() => handleDotClick(index)}
            ></span>
          ))}
        </div>
      </div>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} > {/* Prevent close if not paid */}
        <ModalOverlay />
        <ModalContent>
          <ModalHeader className='pertpaymentpend'>Your Payment is Pending!</ModalHeader>

          <ModalBody>
            <p>Your payment is required to proceed. Please complete the payment.</p>
            <Button mt={"30px"} width={"100%"} background={"#F91919"} color={"#ffffff"} onClick={handleClick}>
              Pay Now
            </Button>
          </ModalBody>


        </ModalContent>
      </Modal>
      <CategoryGrid />
      <Ads />
      <CategoryCol />
      <Vendor />
      <Footer />
    </div>
  );
};

export default Home;
