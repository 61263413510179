import React, { useState } from 'react';
import './Table.css';  // Importing the CSS file

const AdvertiseListing = () => {
  // Sample advertisement data
  const adData = [
    {
      plan: 'Basic Plan',
      adTitle: 'Metro Electronics Sale',
      image: 'ad1.jpg',
      description: 'Exclusive discounts on electronics.',
      website: 'www.metroelectronics.com',
      mobileNumber: '9876543210',
    },
    {
      plan: 'Premium Plan',
      adTitle: 'City Mart Discounts',
      image: 'ad2.jpg',
      description: 'Huge savings on groceries!',
      website: 'www.citymart.com',
      mobileNumber: '9876543211',
    },
    {
      plan: 'Standard Plan',
      adTitle: 'Tech World Launch',
      image: 'ad3.jpg',
      description: 'Latest gadgets at unbeatable prices.',
      website: 'www.techworld.com',
      mobileNumber: '9876543212',
    },
    {
      plan: 'Gold Plan',
      adTitle: 'Grocery Hub Offers',
      image: 'ad4.jpg',
      description: 'Get your essentials at great prices!',
      website: 'www.groceryhub.com',
      mobileNumber: '9876543213',
    },
    {
      plan: 'Silver Plan',
      adTitle: 'Fashion Corner Deals',
      image: 'ad5.jpg',
      description: 'Trendy outfits for every occasion.',
      website: 'www.fashioncorner.com',
      mobileNumber: '9876543214',
    },
  ];

  const [searchTerm, setSearchTerm] = useState('');

  const filteredAds = adData.filter(ad =>
    ad.adTitle.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="table-container">
      <h2>Advertise Listing</h2>
      <div className="search-container">
        <input
          type="text"
          placeholder="Search by ad title..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="table-wrapper">
        <table className="bigbull-table">
          <thead>
            <tr>
              <th>Plan</th>
              <th>Ad Title</th>
              <th>Image</th>
              <th>Description</th>
              <th>Website</th>
              <th>Mobile Number</th>
            </tr>
          </thead>
          <tbody>
            {filteredAds.map((ad, index) => (
              <tr key={index}>
                <td>{ad.plan}</td>
                <td>{ad.adTitle}</td>
                <td><img src={ad.image} alt="Advertisement" width="50" /></td>
                <td>{ad.description}</td>
                <td><a href={ad.website} target="_blank" rel="noopener noreferrer">{ad.website}</a></td>
                <td>{ad.mobileNumber}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdvertiseListing;
