import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import '../CSS/Global.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Vendor from './Vendor';
import BigBullWorldAds from './BigbullWorldAds';
import { useNavigate } from 'react-router-dom';

// Custom arrow components
const NextArrow = ({ onClick }) => {
    return (
        <div className="bigbullworld-arrow bigbullworld-next" onClick={onClick}>
            <i className="fa-solid fa-chevron-right"></i>
        </div>
    );
};

const PrevArrow = ({ onClick }) => {
    return (
        <div className="bigbullworld-arrow bigbullworld-prev" onClick={onClick}>
            <i className="fa-solid fa-chevron-left"></i>
        </div>
    );
};

const BidBullWorld = () => {
    // Dummy store data
    // const stores = [
    //     { id: 1, name: 'Innovatech Systems Pvt. Ltd.', rating: 4.3, ratingsCount: 284, address: '42 Tech Park, Electronic City, Bengaluru, Karnataka 560100', img: 'https://picsum.photos/200?random=1' },
    //     { id: 2, name: 'TechPulse Pvt. Ltd.', rating: 4.1, ratingsCount: 198, address: '10 Avenue Street, Whitefield, Bengaluru, Karnataka 560066', img: 'https://picsum.photos/200?random=2' },
    //     { id: 3, name: 'GroMart Pvt. Ltd.', rating: 4.7, ratingsCount: 352, address: '9th Block, HSR Layout, Bengaluru, Karnataka 560102', img: 'https://picsum.photos/200?random=3' },
    //     { id: 4, name: 'RetailTech Solutions', rating: 4.5, ratingsCount: 225, address: 'IT Hub, Marathahalli, Bengaluru, Karnataka 560037', img: 'https://picsum.photos/200?random=4' },
    //     { id: 5, name: 'Supernova Groceries', rating: 4.2, ratingsCount: 340, address: '14 MG Road, Ulsoor, Bengaluru, Karnataka 560008', img: 'https://picsum.photos/200?random=5' },
    //     { id: 6, name: 'ShopEasy Pvt. Ltd.', rating: 4.0, ratingsCount: 265, address: '8th Cross, Indiranagar, Bengaluru, Karnataka 560038', img: 'https://picsum.photos/200?random=6' },
    //     { id: 7, name: 'AgroWorld Pvt. Ltd.', rating: 4.6, ratingsCount: 312, address: 'Near Forum Mall, Koramangala, Bengaluru, Karnataka 560095', img: 'https://picsum.photos/200?random=7' },
    //     { id: 8, name: 'TechyMart Pvt. Ltd.', rating: 4.3, ratingsCount: 290, address: '3rd Main Road, Jayanagar, Bengaluru, Karnataka 560041', img: 'https://picsum.photos/200?random=8' },
    //     { id: 9, name: 'SuperTech Retailers', rating: 4.8, ratingsCount: 410, address: '12th Avenue, Yelahanka, Bengaluru, Karnataka 560064', img: 'https://picsum.photos/200?random=9' },
    //     { id: 10, name: 'Retailify Pvt. Ltd.', rating: 4.4, ratingsCount: 150, address: 'Bellandur Tech Park, Bengaluru, Karnataka 560103', img: 'https://picsum.photos/200?random=10' }
    // ];

    // Slider settings
    const navigate = useNavigate("")
    const BigBullWorldSettings = {
        dots: false, // Hide dots
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '20px',
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    centerPadding: '10px',
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    centerPadding: '5px',
                }
            }
        ]
    };

    // Function to handle contact button click
    const handleContactClick = () => {
        window.location.href = 'tel:1234567890'; // Replace with actual phone number
    };

    // Function to handle WhatsApp button click
    const handleWhatsAppClick = (storeName) => {
        const message = `Hi, I would like to inquire about ${storeName}`;
        const phoneNumber = '+917715863833'; // Replace with the store's WhatsApp number
        window.open(`https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`, '_blank');
    };

    const [categories, setCategories] = useState([]);
    const [stores, setStores] = useState([]);
    const [error, setError] = useState(null);

    const categoryFetch = async () => {
        try {
            const response = await fetch('https://100billionoms.com/category/getcategory');
            const res = await response.json();
            console.log(res);
            setCategories(res);  // Store categories in state
        } catch (error) {
            setError('Failed to fetch categories');
            console.error(error);
        }
    };

    const Allstorefetch = async () => {
        try {
            const response = await fetch('https://100billionoms.com/store/getstore/user');
            const res = await response.json();
            console.log(res);
            const activeStores = res
        .filter((item) => item.status === "Active")
        .map((store) => ({
          ...store,
          additionalEmails: JSON.parse(store.additionalEmails || "[]"),
          additionalLandlines: JSON.parse(store.additionalLandlines || "[]"),
          additionalMobiles: JSON.parse(store.additionalMobiles || "[]"),
          additionalServices: JSON.parse(store.additionalServices || "[]"),
          additionalWhatsapps: JSON.parse(store.additionalWhatsapps || "[]"),
          businessDetails: JSON.parse(store.businessDetails || "{}"),
          businessTiming: JSON.parse(store.businessTiming || "{}"),
          contactDetails: JSON.parse(store.contactDetails || "{}"),
          formImages: JSON.parse(store.formImages || "[]"),
          paymentDetails: JSON.parse(store.paymentDetails || "{}"),
          servicesDetails: JSON.parse(store.servicesDetails || "{}"),
        }));
  
      // Set the parsed and filtered data to state
      setStores(activeStores);
            // setStores(res);  // Store the fetched stores in state
        } catch (error) {
            setError('Failed to fetch stores');
            console.error(error);
        }
    };

    const [ratingsSummary, setRatingsSummary] = useState({}); // Object with store_id as key and { count, average } as value

// Function to fetch ratings summary (count and average) for each store
const fetchRatingsSummary = async (storeId) => {
    try {
        const response = await fetch(`https://100billionoms.com/rating/summary/${storeId}`);
        const res = await response.json();
        if (!res.isError) {
            setRatingsSummary(prevSummary => ({
                ...prevSummary,
                [storeId]: {
                    count: res.count || 0,
                    average: res.average || 0
                }
            }));
        }
    } catch (error) {
        console.error("Failed to fetch ratings summary:", error);
    }
};




    // Function to truncate store names
    const truncateStoreName = (name) => {
        return name.length > 20 ? name.slice(0, 20) + '...' : name;
    };

    useEffect(() => {
        categoryFetch();
        Allstorefetch();
    }, [])

    useEffect(() => {
        stores.forEach((store) => {
            fetchRatingsSummary(store.user_id); // Fetch ratings summary for each store ID
        });
    }, [stores]);

    const renderCategory = (title) => (
        <div className="bigbullworld">
            <h2>{title}</h2>
            <Slider {...BigBullWorldSettings}>
                {stores.map(store => (
                    <div key={store.id} className="bigbullworld-store-card">
                        <div className="category-store-card-image">
                            <img src={`https://100billionoms.com/${store.formImages[0]}`} alt={store.name} />
                        </div>
                        <div className="category-store-info">
                            <div className="category-store-card-name">
                                <p>{truncateStoreName(store.businessDetails.businessName)}</p>
                            </div>
                            <div className="category-store-card-rating">
                                <p className="review">{store.rating} <i className="fa-solid fa-star"></i></p>
                                <p className="reviewnumber">{store.ratingsCount} Ratings</p>
                                <p className="verify"><i className="fa-solid fa-circle-check"></i> Verified</p>
                            </div>
                            <div className="category-store-card-address">
                                <p>{store.address}</p>
                            </div>
                            <div className="category-store-card-buttons">
                                <button className="contact-button" onClick={handleContactClick}>
                                    <i className="fa-solid fa-phone"></i> Contact
                                </button>
                                <button className="whatsapp-button" onClick={() => handleWhatsAppClick(store.name)}>
                                    <i className="fa-brands fa-whatsapp"></i> WhatsApp
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );


    return (
        <>
            <BigBullWorldAds />
            {categories.map((category) => {
                // Filter stores based on matching category name
                const filteredStores = stores.filter((store) => store.category === category.category_name && store.status === "Active");
                console.log(filteredStores)
                // If no stores match the category, don't render the section
                if (filteredStores.length === 0) {
                    return null;
                }

                return (
                    <div className="bigbullworld">
                        <h2>{category.category_name}</h2>
                        <Slider {...BigBullWorldSettings}>
                            {filteredStores.map(store => (
                                <div onClick={() => navigate(`/detail-listing/${store.id}/${store.businessDetails.businessName}`)} key={store.id} className="bigbullworld-store-card">
                                    <div className="category-store-card-image">
                                        <img src={`https://100billionoms.com/${store.formImages[0]}`} alt={store.name} />
                                    </div>
                                    <div className="category-store-info">
                                        <div className="category-store-card-name">
                                            <p>{truncateStoreName(store.businessDetails.businessName)}</p>
                                        </div>
                                        <div className="category-store-card-rating">
                                            <p className="review">{ratingsSummary[store.user_id]?.average || 0} <i className="fa-solid fa-star"></i></p>
                                            <p className="reviewnumber">{ratingsSummary[store.user_id]?.count || 0} Ratings Ratings</p>
                                            <p className="verify"><i className="fa-solid fa-circle-check"></i> Verified</p>
                                        </div>
                                        <div className="category-store-card-address">
                                            <p>{store.address}</p>
                                        </div>
                                        <div className="category-store-card-buttons">
                                            <button className="contact-button" onClick={handleContactClick}>
                                                <i className="fa-solid fa-phone"></i> Contact
                                            </button>
                                            <button className="whatsapp-button" onClick={() => handleWhatsAppClick(store.name)}>
                                                <i className="fa-brands fa-whatsapp"></i> WhatsApp
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                );
            })}
            <Vendor />
            <BigBullWorldAds />
        </>
    );

};

export default BidBullWorld;
