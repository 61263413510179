import '../CSS/Global.css'

const Padding = () => {
    return(
        <>
            <div className="padding-footer">
                <div className="padding-footer-content"></div>
            </div>
        </>
    );
}

export default Padding;