import React, { useState } from 'react';
import './Table.css';  // Importing the CSS file

const UserListing = () => {
    // Sample user data
    const userData = [
        {
            email: 'alice@example.com',
            name: 'Alice Smith',
            password: 'password123'
        },
        {
            email: 'bob@example.com',
            name: 'Bob Johnson',
            password: 'password123'
        },
        {
            email: 'charlie@example.com',
            name: 'Charlie Brown',
            password: 'password123'
        },
        {
            email: 'dave@example.com',
            name: 'Dave Wilson',
            password: 'password123'
        },
        {
            email: 'eve@example.com',
            name: 'Eve Davis',
            password: 'password123'
        },
    ];

    const [searchTerm, setSearchTerm] = useState('');

    const filteredUsers = userData.filter(user =>
        user.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="table-container">
            <h2>User Registration Listing</h2>
            <div className="search-container">
                <input
                    type="text"
                    placeholder="Search by name..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>
            <div className="table-wrapper">
                <table className="bigbull-table">
                    <thead>
                        <tr>
                            <th>Email</th>
                            <th>Name</th>
                            <th>Password</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredUsers.map((user, index) => (
                            <tr key={index}>
                                <td>{user.email}</td>
                                <td>{user.name}</td>
                                <td>{user.password}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default UserListing;
