import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { ChakraProvider } from '@chakra-ui/react';
import { GlobalProvider } from './Components/Context/GlobalContext';


ReactDOM.render(
  <BrowserRouter>
    <ChakraProvider>
      <GlobalProvider>
        <App />
      </GlobalProvider>
    </ChakraProvider>
  </BrowserRouter>,
  document.getElementById('root')
);
