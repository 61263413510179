// src/TimeCountDown.js
import React, { useState, useEffect } from 'react';
import '../CSS/Global.css';

const TimeCountDown = ({timeRemaining,enddate}) => {
  console.log(enddate)
  const calculateTimeLeft = () => {
    if (!enddate) return { days: 0, hours: 0, minutes: 0, seconds: 0 };

    const targetDate = new Date(enddate); // Ensure `enddate` is a valid date
    const currentDate = new Date();

    const difference = targetDate - currentDate;

    if (difference <= 0) {
      // If the target date has passed, return zeros
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    let days = Math.floor(difference / (1000 * 60 * 60 * 24));
    let hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer); // Cleanup on component unmount
  }, [enddate]);

  const formatTime = (time) => (time < 10 ? `0${time}` : time); // Format numbers to two digits

  return (
    <div className="time-countdown">
      <div className="time-countdown-list">
        <h3>{formatTime(timeLeft.days)}</h3>
        <p>Days</p>
      </div>
      <div className="time-countdown-list">
        <h3>{formatTime(timeLeft.hours)}</h3>
        <p>Hours</p>
      </div>
      <div className="time-countdown-list">
        <h3>{formatTime(timeLeft.minutes)}</h3>
        <p>Minutes</p>
      </div>
      <div className="time-countdown-list">
        <h3>{formatTime(timeLeft.seconds)}</h3>
        <p>Seconds</p>
      </div>
    </div>
  );
};

export default TimeCountDown;
