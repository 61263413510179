import { Box, Button, Flex, Input, Spinner, Text, useDisclosure } from "@chakra-ui/react";
import Map from "./Map";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import { useGlobalContext } from "./Context/GlobalContext";



function Address() {
    let latitude = JSON.parse(localStorage.getItem("lat"))
    let longitude = JSON.parse(localStorage.getItem("lon"))
    let address = JSON.parse(localStorage.getItem("address"));
    let [orderplace, setOrderPlace] = useState("Home");
    let login = JSON.parse(localStorage.getItem("login")) || "";
    const [queryFromChild, setQueryFromChild] = useState('');
    let [allAdd, setAdd] = useState([]);
    let navigate = useNavigate()
    const {
        isOpen,
        onOpen,
        onClose,
        isModal1Open,
        openModal1,
        closeModal1,
        fetchCartData,
        cart,
        totalCartItem
    } = useGlobalContext();

    const handleQueryFromChild = (query) => {
        setQueryFromChild(query);
    };
    const API_KEY = "AIzaSyD0AcxoBWhqkMIAfK-F-X6ya3Es-52o1YU";
    let [address1, setAddress1] = useState()
    console.log(address)
    console.log(typeof latitude, typeof longitude)
    const [selectedLocation, setSelectedLocation] = useState({
        lat: latitude,
        lng: longitude,
    });


    console.log(selectedLocation);

    console.log(latitude, longitude)

   
    const { isOpen: isModal2Open, onOpen: openModal2, onClose: closeModal2 } = useDisclosure();



    console.log(queryFromChild);



    let handleChange = (placeget) => {
        if (orderplace === placeget) {
            setOrderPlace(placeget);
        } else {
            setOrderPlace(placeget);
        }
    }
    let fetchAddress = async () => {
        try {
            let response = await fetch(`https://100billionoms.com/address/${login.permanent_ID}`)
            let res = await response.json();
            console.log(res.user)
            setAdd(res.user)
            // setAdd({...allAdd, ...res.user})
        } catch (error) {
            console.log(error)
        }

    }
    console.log(selectedLocation)
    useEffect(() => {
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${selectedLocation.lat},${selectedLocation.lng}&sensor=false&key=AIzaSyD0AcxoBWhqkMIAfK-F-X6ya3Es-52o1YU`;
        fetch(url)
            .then((res) => res.json())
            .then((res) => {
                console.log(res);
                // setAddress(res.results[0].formatted_address);
                console.log(res.results[0].formatted_address);
                setAddress1(res.results[0].formatted_address)
            })
            .catch((error) => {
                console.log(error);

            });

    }, [selectedLocation])


    let handleDelete = async (id) => {
        try {
            let response = await fetch(`https://100billionoms.com/address/${id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json"
                }

            });
            if (response.ok) {
                // Remove the deleted address from the state
                setAdd(prevState => prevState.filter(address => address.id !== id));
                console.log("Address deleted successfully.");
            } else {
                console.log("Failed to delete address.");
            }
        } catch (error) {
            console.log(error);
        }
    }


    let [data, setData] = useState({
        location_user: orderplace,
        user_id: login.permanent_ID,
        flatno: "",
        floor: "",
        area: "",
        landmark: "",
        name:login.name,
        number: "",
        email:login.email,
        latitude: selectedLocation.lat,
        longitude: selectedLocation.lng
    });

    console.log(data);
    const storeData = (e) => {

        setData({ ...data, [e.target.name]: e.target.value });
    }


    let saveUserAddress = async (e) => {
        e.preventDefault();
        try {
            const postData = {
                ...data,
                // Use address1 if queryFromChild is empty, otherwise use queryFromChild
                area: queryFromChild === "" ? address1 : queryFromChild,
            };
            console.log("postData:", postData); // Log the postData object
            let response = await fetch(`https://100billionoms.com/address`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(postData),
            })
            let res = await response.json();
            if (response.ok) {
                onOpen();
                navigate(-1)
            }
            console.log(res)
        } catch (error) {
            console.log(error);
        }
    }


    const updateDefaultAddress = async (id) => {
        try {
            // Set all addresses' defaultaddress to false
            setAdd(prevState =>
                prevState.map(address => {
                    return { ...address, defaultaddress: false };
                })
            );

            // Update the clicked address to default
            const response = await fetch(`https://100billionoms.com/address/${id}`, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ defaultaddress: true }),
            });

            if (response.ok) {
                // Update the default address in the state
                setAdd(prevState =>
                    prevState.map(address => {
                        if (address.id === id) {
                            return { ...address, defaultaddress: true };
                        } else {
                            return address;
                        }
                    })
                );
                console.log(allAdd)
                console.log("Default address updated successfully.");
            } else {
                console.log("Failed to update default address.");
            }
        } catch (error) {
            console.log(error);
        }
    };

    // Function to handle clicking on an address
    const handleClickAddressMobile = async (id) => {
        try {
            // Update the default address
            await updateDefaultAddress(id);

            // Find the clicked address from allAdd array
            const clickedAddress = allAdd.find(address => address.id === id);

            // Serialize the clicked address object before storing in local storage
            const serializedAddress = JSON.stringify(clickedAddress);

            // Store the serialized address in local storage
            localStorage.setItem('clickedAddress', serializedAddress);

            // Close the modal
            navigate("/cart");

            // Perform any other action needed when clicking on an address
        } catch (error) {
            console.log(error);
        }
    };

    const handleClickAddress = async (id) => {
        try {
            // Update the default address
            await updateDefaultAddress(id);

            // Find the clicked address from allAdd array
            const clickedAddress = allAdd.find(address => address.id === id);

            // Serialize the clicked address object before storing in local storage
            const serializedAddress = JSON.stringify(clickedAddress);

            // Store the serialized address in local storage
            localStorage.setItem('clickedAddress', serializedAddress);

            // Close the modal
            // onClose();

            // Perform any other action needed when clicking on an address
        } catch (error) {
            console.log(error);
        }
    };



    // Check if there's only one address and set it as clicked address
    useEffect(() => {
        if (allAdd.length === 1) {
            const clickedAddress = allAdd[0];
            const serializedAddress = JSON.stringify(clickedAddress);
            localStorage.setItem('clickedAddress', serializedAddress);
        }
    }, [allAdd]);

    //  let handleDetect = () => {
    //     if (!navigator.geolocation) {
    //         // Geolocation is not supported by the browser
    //         console.log("Geolocation is not supported by your browser.");
    //         return;
    //     }

    //     // Try to get the current position
    //     // setLoad(false);
    //     navigator.geolocation.getCurrentPosition(
    //         (position) => {
    //             console.log(position.coords);
    //             setLongitude(position.coords.longitude);
    //             setLatitude(position.coords.latitude);
    //             localStorage.setItem("lat", position.coords.latitude)
    //             localStorage.setItem("lon", position.coords.longitude)
    //             const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&sensor=false&key=AIzaSyD0AcxoBWhqkMIAfK-F-X6ya3Es-52o1YU`;
    //             fetch(url)
    //                 .then((res) => res.json())
    //                 .then((res) => {
    //                     console.log(res);
    //                     // calculateRoute(res.results[0].formatted_address);
    //                     // // setAddress(res.results[0].formatted_address);
    //                     localStorage.setItem("address", JSON.stringify(res.results[0].formatted_address))
    //                     // setLoad(true);
    //                 })
    //                 .catch((error) => {
    //                     console.log(error);
    //                     // setLoad(false);
    //                 });
    //         },
    //         () => {
    //             // If the user denied geolocation access
    //             console.log("Please allow geolocation access to detect your location.");
    //             // setLocationWarning(true);
    //             // openModal4()
    //             // setLoad(true); // Reset the loading state
    //         }
    //     );
    // };

    useEffect(() => {
        fetchAddress();
        // handleDetect();
    }, [])
    return (
        <>

            <Header/>
            <Box mt={"10px"}>
                <Box w={{base:"100%",sm:"100%",md:"100%",lg:"80%",xl:"80%"}}  m={"auto"} p={"20px"} boxShadow={"0 0 8px 0 rgba(0,0,0,0.1)"} borderRadius={"10px"}>
                    <Flex flexDirection={{base:"column",sm:"column",md:"column",lg:"row",xl:"row"}} alignItems={"center"}>
                        <Box  w={{base:"100%",sm:"100%",md:"100%",lg:"50%",xl:"50%"}}  position={"relative"}>
                            <Map selectedLocation={selectedLocation} setAddress1={setAddress1} setSelectedLocation={setSelectedLocation} address1={address1} query={queryFromChild} onQueryChange={handleQueryFromChild} />
                        </Box>
                        <Box w={{base:"100%",sm:"100%",md:"100%",lg:"50%",xl:"50%"}} h={"100vh"} overflowY={"auto"}>
                            <Box p={"14px 12px"} borderBottom={"1px solid gray"} >
                                <Flex alignItems={"center"}>
                                    <Text fontSize={"17px"} fontWeight={"bold"}>Enter  complete address</Text>

                                </Flex>
                            </Box>
                            <Box p={"24px"}>
                                <Text>Save address as *</Text>
                                <Box mt={"3px"}>
                                    <Flex justifyContent={"space-between"}>
                                        <Box p={"6px"} borderRadius={"10px"}
                                            onClick={() => handleChange("Home")}
                                            cursor={"pointer"}
                                            bg={orderplace === "Home" ? "#F91919" : "transparent"}
                                            color={orderplace === "Home" ? "#fff" : "black"}
                                            border={orderplace === "Home" ? "1px solid #F91919" : "1px solid transparent"}
                                            boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px">
                                            <Flex color={orderplace === "Home" ? "#fff" : "black"} alignItems={"center"} gap={"5px"}>
                                                <i class="fa-solid fa-house"></i>
                                                <Text fontSize={"12px"}>Home</Text>
                                            </Flex>
                                        </Box>
                                        <Box p={"6px"}
                                            onClick={() => handleChange("Work")}
                                            cursor={"pointer"}
                                            bg={orderplace === "Work" ? "#F91919" : "transparent"}
                                            color={orderplace === "Work" ? "#fff" : "black"}
                                            border={orderplace === "Work" ? "1px solid #F91919" : "1px solid transparent"}
                                            borderRadius={"10px"} boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px">
                                            <Flex alignItems={"center"} gap={"5px"}>
                                                <i class="fa-solid fa-briefcase"></i>
                                                <Text fontSize={"12px"}>Work</Text>
                                            </Flex>
                                        </Box>
                                        <Box p={"6px"}
                                            onClick={() => handleChange("Hotel")}
                                            cursor={"pointer"}
                                            bg={orderplace === "Hotel" ? "#F91919" : "transparent"}
                                            color={orderplace === "Hotel" ? "#fff" : "black"}
                                            border={orderplace === "Hotel" ? "1px solid #F91919" : "1px solid transparent"}
                                            borderRadius={"10px"} boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px">
                                            <Flex alignItems={"center"} gap={"5px"}>
                                                <i class="fa-solid fa-building"></i>
                                                <Text fontSize={"12px"}>Hotel</Text>
                                            </Flex>
                                        </Box>
                                        <Box p={"6px"}
                                            onClick={() => handleChange("Other")}
                                            cursor={"pointer"}
                                            bg={orderplace === "Other" ? "#F91919" : "transparent"}
                                            color={orderplace === "Other" ? "#fff" : "black"}
                                            border={orderplace === "Other" ? "1px solid #F91919" : "1px solid transparent"}
                                            borderRadius={"10px"} boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px">
                                            <Flex alignItems={"center"} gap={"5px"}>
                                                <i class="fa-solid fa-location-dot"></i>
                                                <Text fontSize={"12px"}>Other</Text>
                                            </Flex>
                                        </Box>
                                    </Flex>
                                </Box>
                            </Box>
                            <Box p={"5px 24px 24px 24px"}>
                                <form onSubmit={saveUserAddress}>
                                    <Box>
                                        <Text mb={"5px"}>Flat/House No/Building name</Text>
                                        <Input type='text' name='flatno' value={data.flatno} onChange={storeData} isRequired />
                                    </Box>
                                    <Box mt={"10px"}>
                                        <Text mb={"5px"}>Floor (optional)</Text>
                                        <Input type='text' name='floor' value={data.floor} onChange={storeData} />
                                    </Box>
                                    <Box mt={"10px"}>
                                        <Text mb={"5px"}>Area/Sector/Locality</Text>
                                        <Input
                                            name='area'
                                            value={queryFromChild === "" ? address1 : queryFromChild}
                                            onChange={storeData}
                                            disabled />
                                    </Box>
                                    <Box mt={"10px"}>
                                        <Text mb={"5px"}>Nearby Landmark (optional)</Text>
                                        <Input type='text' name='landmark'
                                            value={data.landmark}
                                            onChange={storeData}
                                        />
                                    </Box>
                                    <Text mt="10px">Enter your details for seamless delivery experience</Text>
                                    <Box mt={"10px"}>
                                        <Text mb={"5px"}>Your Name</Text>
                                        <Input type='text'
                                            name='name'
                                            value={data.name}
                                            onChange={storeData}
                                            isRequired
                                        />
                                    </Box>
                                    <Box mt={"5px"}>
                                        <Text >Email</Text>
                                        <Input type='text'
                                            name='email'
                                            p={"15px 30px 15px 12px"}
                                            value={data.email}
                                            onChange={storeData}
                                            placeholder='Your email'

                                        />
                                    </Box>
                                    <Box mt={"10px"}>
                                        <Text mb={"5px"}>Phone Number</Text>
                                        <Input type='text'
                                            name='number'
                                            value={data.number}
                                            onChange={storeData}
                                            isRequired
                                        />
                                    </Box>
                                    <Button mt={"20px"} bg={"#F91919"} color={"white"} w={"100%"} type='submit'>
                                        Save Address
                                    </Button>
                                    {/* {
                                    load ? <Button bg={"green"} color={"white"} w={"100%"} type='submit'>
                                        <Spinner />
                                    </Button> : <Button bg={"green"} color={"white"} w={"100%"} type='submit'>
                                        Save Address
                                    </Button>
                                } */}
                                </form>
                            </Box>
                        </Box>
                    </Flex>
                </Box>
            </Box>

        </>
    )
}

export default Address;