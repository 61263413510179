import React, { useEffect, useState } from 'react';
import { Box, Text, IconButton, SimpleGrid, Image, Button, Flex, useBreakpointValue } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Link, useNavigate } from 'react-router-dom';

const VendorCard = () => {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);

  const [vendors, setVendors] = useState([])

  const parseStoreData = (store) => {
    return {
      ...store,
      businessDetails: JSON.parse(store.businessDetails || "{}"),
      businessTiming: JSON.parse(store.businessTiming || "{}"),
      contactDetails: JSON.parse(store.contactDetails || "{}"),
      formImages: JSON.parse(store.formImages || "[]"),
      additionalServices: JSON.parse(store.additionalServices || "[]"),
      paymentDetails: JSON.parse(store.paymentDetails || "{}"),
      servicesDetails: JSON.parse(store.servicesDetails || "{}"),
    };
  };

  const fetchDataTrending = async () => {
    try {
      const response = await fetch(`https://100billionoms.com/trending/alltrends/alltrendinglist`)
      const res = await response.json();
      console.log(res);
      // setVendors(res.data);
      setVendors(res.data.map(parseStoreData));

    } catch (error) {
      console.log(error)
    }
  }
  const chunkSize = useBreakpointValue({ base: 1, sm: 2, md: 3, lg: 4 });
  const chunkedVendors = Array.from(
    { length: Math.ceil(vendors.length / chunkSize) },
    (_, i) => vendors.slice(i * chunkSize, i * chunkSize + chunkSize)
  );

  const handleSlide = (direction) => {
    setCurrentIndex((prevIndex) => prevIndex + direction);
  };

  // const renderStars = (rating) => {
  //   const fullStars = Math.floor(rating);
  //   const hasHalfStar = rating % 1 >= 0.5;
  //   const stars = Array(fullStars).fill('full');
  //   if (hasHalfStar) stars.push('half');

  //   return (
  //     <Box className="vendor-review" textAlign="center" >
  //       {stars.map((star, index) => (
  //         <i key={index} className={`fa-solid ${star === 'full' ? 'fa-star' : 'fa-star-half-alt'}`} />
  //       ))}
  //     </Box>
  //   );
  // };

  useEffect(() => {
    fetchDataTrending();
  }, [])


  return (
    <>
      {
        vendors.length > 0 ? <Box position="relative" p={5} mt={"20px"} mb={"20px"}>
          <Text fontSize="2xl" mb={4} ml={4} >Trending Vendors</Text>

          <IconButton
            icon={<ChevronLeftIcon boxSize={6} />}
            aria-label="Previous"
            onClick={() => handleSlide(-1)}
            position="absolute"
            top="50%"
            left="5px"
            transform="translateY(-50%)"
            borderRadius="full"
            bg="white"
            boxShadow="md"
            _hover={{ bg: 'gray.100' }}
            disabled={currentIndex === 0} // Disable when at the start
          />
          <IconButton
            icon={<ChevronRightIcon boxSize={6} />}
            aria-label="Next"
            onClick={() => handleSlide(1)}
            position="absolute"
            top="50%"
            right="5px"
            transform="translateY(-50%)"
            borderRadius="full"
            bg="white"
            boxShadow="md"
            _hover={{ bg: 'gray.100' }}
            disabled={currentIndex === chunkedVendors.length - 1} // Disable when at the end
          />

          <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 4 }} spacing={5} ml={3} mr={3} >
            {chunkedVendors[currentIndex]?.map((vendor) => (
              <Box
                p={{ base: "10px", md: "20px 10px 10px 10px" }}
                key={vendor.id}
                borderWidth="1px"
                borderRadius="lg"
                overflow="hidden"
                boxShadow="md"
              // onClick={() => navigate(`/detail-listing/${vendor.id}/${vendor.businessName}`)}
              >
                <Image
                  className="vendor-img"
                  src={`https://100billionoms.com/${vendor.formImages[0]}`}
                  alt={vendor.name}

                />
                <Box p={4}>
                  <Text fontWeight="bold" textAlign="center" fontSize="18px" mb="8px">
                    {vendor.contactDetails.personName}
                  </Text>
                  <Text textAlign="center" >
                    {vendor.businessDetails.businessName}
                  </Text>
                  {/* {renderStars(vendor.rating)} */}
                  <Flex mt={"20px"} justify="space-between">
                    <Link to={`tel:${vendor.contactDetails && vendor.contactDetails.mobile}`}>
                      <Button border={"1px solid red"} bg={"white"} color={"red"} _hover={{ bg: "red", color: "white" }} leftIcon={<i className="fa-solid fa-phone fa-sm"></i>}>
                        Contact
                      </Button>
                    </Link>
                    <Button border={"1px solid red"} bg={"white"} marginLeft={'10px'}
                      onClick={() =>
                        navigate(`/detail-listing/${vendor.storeId}/${vendor.businessDetails.businessName}`)
                      }
                      color={"red"} _hover={{ bg: "red", color: "white" }} leftIcon={<i className="fa-solid fa-location-dot fa-sm"></i>}>
                      View Store
                    </Button>
                  </Flex>
                </Box>
              </Box>
            ))}
          </SimpleGrid>
        </Box> : null
      }
    </>
  );
};

export default VendorCard;
