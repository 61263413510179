import { useEffect, useState } from 'react';
import '../CSS/Global.css';
import Showqr from '../Images/Showqr.png';
import user from "../Images/user.jpg"
import { Box, Button, Flex } from '@chakra-ui/react';
import Header from './Header';

const ShowQR = () => {
    const login = JSON.parse(localStorage.getItem("login")) || ""
    const [qruse, setQr] = useState()
    const [permanentID, setPermanent] = useState()
    const [username, setUsername] = useState()
    const [profile, setProfile] = useState();

    const fetchShow = async () => {
        try {
            const response = await fetch(`https://100billionoms.com/register/singleuser/${login.permanent_ID}`)
            const res = await response.json();
            setQr(res.scanqr)
            setPermanent(res.permanent_ID)
            setUsername(res.name)
            setProfile(res.profilePic);
            // console.log(res);
        } catch (error) {
            console.log(error)
        }
    }

    const handleGenrateQr = async () => {
        try {
            const response = await fetch(`https://100billionoms.com/register/api/generate-qr`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ permanent_ID: login.permanent_ID }),
            })
            const res = await response.json();
            console.log(res);
            if (response.ok) {
                fetchShow();
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchShow();
    }, [])
    return (
        <>
        <Header/>
            <div className='qr'>
                <div className='qr-main'>
                    {/* Placeholder image for lorem image */}
                    <img src={profile === null ? user : `https://100billionoms.com/${profile}`} alt='Placeholder' className='person-img' />

                    <p className='name'>{username}</p>

                    <div className='qr-img'>
                        {
                            qruse === null ?
                                <Box marginTop={"40px"} >

                                    <Button bg={"#F91919"} onClick={handleGenrateQr} color={"#fff"} display={"block"} margin={"auto"}>Generate QR</Button>

                                </Box>
                                : <img src={`${qruse}`} alt='QR Code' />

                        }

                    </div>

                    <div className='qr-id'>
                        <p>Permanent ID: {permanentID}</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ShowQR;
