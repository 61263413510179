import React, { useEffect, useState } from 'react';
import '../CSS/Global.css';
import { useNavigate, useParams } from 'react-router-dom';
import Header from './Header';

const Cosmetics = () => {
  const categories = [
    { id: 6, name: 'Skin Care', image: 'https://via.placeholder.com/80?text=Skin+Care' },
    { id: 7, name: 'Hair Care', image: 'https://via.placeholder.com/80?text=Hair+Care' },
    { id: 8, name: 'Makeup', image: 'https://via.placeholder.com/80?text=Makeup' },
    { id: 9, name: 'Fragrances', image: 'https://via.placeholder.com/80?text=Fragrances' },
    { id: 10, name: 'Bath & Body', image: 'https://via.placeholder.com/80?text=Bath+&+Body' },
  ];

  const { category } = useParams();
  const [subcategory,setSubCategory]=useState([])
  const navigate=useNavigate();

  const fetchCategory = async () => {
    try {
      const response = await fetch(`https://100billionoms.com/category/singlecategory/${category}`)
      const res = await response.json();
      setSubCategory(res.associatedsubcategory)
      console.log(res);
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchCategory();
  }, [])
  
  console.log(subcategory);
  return (
    <>
    <Header/>
    <div className="category-section">
      <h2 className="category-heading">{category} Categories</h2>
      <div className="category-grid">
        {subcategory.map(category => (
          <div key={category.id} onClick={()=>navigate(`/subcategory/${category.subcategory_name}`)}  className="category-item">
            <div className="category-image">
              <img src={`https://100billionoms.com/${category.subcat_image}`} alt={category.subcategory_name} />
            </div>
            <p className="category-name">{category.subcategory_name}</p>
          </div>
        ))}
      </div>
    </div>
    </>
  );
};

export default Cosmetics;
