// src/components/CategoryGrid.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import grocery from '../Images/001-groceries.png';
import cosmetics from '../Images/002-cosmetics.png';
import fastFood from '../Images/003-fast-food.png';
import vegetables from '../Images/004-vegetable.png';
import hotels from '../Images/005-hotel.png';
import fish from '../Images/006-fish.png';
import egg from '../Images/007-chicken.png';
import meat from '../Images/008-meat.png';
import fruits from '../Images/009-fruits.png';
import dairy from '../Images/010-milk.png';
import juice from '../Images/011-drink.png';
import medical from '../Images/012-syringe.png';
import rickshaw from '../Images/013-rickshaw.png';
import tour from '../Images/014-travel-and-tourism.png';
import garment from '../Images/015-clothing.png';
import '../CSS/Global.css';

// const categories = [
//   { id: 1, name: 'Grocery', image: grocery, route: '/grocery' },
//   { id: 2, name: 'Cosmetics', image: cosmetics, route: '/cosmetics' },
//   { id: 3, name: 'Fast Food', image: fastFood, route: '/fast-food' },
//   { id: 4, name: 'Vegetables', image: vegetables, route: '/vegetables' },
//   { id: 5, name: 'Hotels', image: hotels, route: '/hotels' },
//   { id: 6, name: 'Fish', image: fish, route: '/fish' },
//   { id: 7, name: 'Egg/Chicken', image: egg, route: '/egg-chicken' },
//   { id: 8, name: 'Meat', image: meat, route: '/meat' },
//   { id: 9, name: 'Fruits', image: fruits, route: '/fruits' },
//   { id: 10, name: 'Dairy Products', image: dairy, route: '/dairy-products' },
//   { id: 11, name: 'Juice', image: juice, route: '/juice' },
//   { id: 12, name: 'Medicals', image: medical, route: '/medicals' },
//   { id: 13, name: 'Rickshaw/Taxi', image: rickshaw, route: '/rickshaw-taxi' },
//   { id: 14, name: 'Tours & Travels', image: tour, route: '/tours-travels' },
//   { id: 15, name: 'Readymade & Garments', image: garment, route: '/readymade-garments' },
// ];

const CategoryGrid = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  
  const fetchCategoryData = async () => {
    try {
      const response = await fetch(`https://100billionoms.com/category/getcategory`)
      const res = await response.json();
      console.log(res);
      const activeImages = res.filter(image => image.status === 'Active')
      .sort((a, b) => a.position - b.position);
      setCategories(activeImages);
    } catch (error) {
      console.log(error)
    }
  }

  console.log(categories)

  const handleClick=(subcategory,category)=>{
    console.log(category,)
    if(subcategory.length>0){
      navigate(`/sub/${category}`)
    }else{
      navigate(`${category}`)
    }
  }
  useEffect(() => {
    fetchCategoryData();
  }, [])
  return (
    <div className="category-grid">
      {categories.map((category) => (
        <div
          key={category.id}
          className="category-item"
          onClick={() => handleClick(category.associatedsubcategory,category.category_name)}
        >
          <div className="category-image">
            <img src={`https://100billionoms.com/${category.cat_image}`} alt={category.category_name} />
          </div>
          <p className="category-name">{category.category_name}</p>
        </div>
      ))}
    </div>
  );
};

export default CategoryGrid;
