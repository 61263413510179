// src/GlobalContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useDisclosure } from '@chakra-ui/react';

const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isModal1Open, onOpen: openModal1, onClose: closeModal1 } = useDisclosure();
    const [cart, setCart] = useState([]);
    const [totalCartItem, setTotalCartItem] = useState(0);
    const login = JSON.parse(localStorage.getItem("login")) || ""
    const [totalhandle, setTotalHandle] = useState(0);
    const [totaldelivery, setTotalDelivery] = useState([])

    const fetchCartData = async () => {
        try {
            const login = JSON.parse(localStorage.getItem("login")) || "";
            const response = await fetch(`https://100billionoms.com/cart/${login.permanent_ID}`);
            const res = await response.json();
    
            // Parse the product field in each cart item
            const parsedCart = res.map(item => ({
                ...item,
                product: JSON.parse(item.product || "{}"), // Parse the product JSON string
            }));
    
            console.log(parsedCart);
            setTotalCartItem(parsedCart.length);
            setCart(parsedCart); // Set the parsed cart data
        } catch (error) {
            console.error("Error fetching cart data:", error);
        }
    };
    

    const handlingChargedata = async () => {
        try {
            const response = await fetch(`https://100billionoms.com/handling-charge`)
            const res = await response.json();
            console.log(res);
            setTotalHandle(res.data[0].handling_price);
        } catch (error) {
            console.log(error)
        }
    }

    const DeliveryChargedata = async () => {
        try {
            const response = await fetch(`https://100billionoms.com/deliverym-charge`)
            const res = await response.json();
            setTotalDelivery(res.data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchCartData();
        handlingChargedata();
        DeliveryChargedata();
    }, []);

    return (
        <GlobalContext.Provider
            value={{
                isOpen,
                onOpen,
                onClose,
                isModal1Open,
                openModal1,
                closeModal1,
                fetchCartData,
                cart,
                totalCartItem,
                totaldelivery,
                setTotalDelivery,
                totalhandle
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
};

// Custom hook for accessing the global context
export const useGlobalContext = () => useContext(GlobalContext);
