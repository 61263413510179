import React, { useState } from 'react';
import './Table.css';  // Importing the CSS file

const StoreListing = () => {
    // Sample store data
    const storeData = [
        {
            storeName: 'Metro Electronics',
            pincode: '560001',
            buildingName: 'Metro Plaza',
            street: 'MG Road',
            area: 'CBD Area',
            landmark: 'Near Central Mall',
            city: 'Bangalore',
            state: 'Karnataka',
            personName: 'Rajesh Kumar',
            mobileNumber: '9876543210',
            whatsappNumber: '9876543210',
            email: 'rajesh@metro.com',
            workingDays: 'Mon-Sun',
            openTime: '10:00 AM',
            closeTime: '8:00 PM',
            images: 'store1.jpg',
            beneficiariesName: 'Rajesh Kumar',
            accountNumber: '987654321098',
            bankName: 'State Bank of India',
            ifscCode: 'SBIN0001234',
        },
        {
            storeName: 'City Mart',
            pincode: '560002',
            buildingName: 'City Centre',
            street: 'Church Street',
            area: 'Central Bangalore',
            landmark: 'Near Brigade Road',
            city: 'Bangalore',
            state: 'Karnataka',
            personName: 'Suresh Verma',
            mobileNumber: '9876543211',
            whatsappNumber: '9876543211',
            email: 'suresh@citymart.com',
            workingDays: 'Mon-Sun',
            openTime: '9:00 AM',
            closeTime: '10:00 PM',
            images: 'store2.jpg',
            beneficiariesName: 'Suresh Verma',
            accountNumber: '987654321099',
            bankName: 'HDFC Bank',
            ifscCode: 'HDFC0001234',
        },
        {
            storeName: 'Tech World',
            pincode: '560003',
            buildingName: 'Tech Park',
            street: 'Indiranagar',
            area: 'East Bangalore',
            landmark: 'Near 100 Feet Road',
            city: 'Bangalore',
            state: 'Karnataka',
            personName: 'Amit Singh',
            mobileNumber: '9876543212',
            whatsappNumber: '9876543212',
            email: 'amit@techworld.com',
            workingDays: 'Mon-Sun',
            openTime: '11:00 AM',
            closeTime: '9:00 PM',
            images: 'store3.jpg',
            beneficiariesName: 'Amit Singh',
            accountNumber: '987654321100',
            bankName: 'ICICI Bank',
            ifscCode: 'ICIC0001234',
        },
        {
            storeName: 'Grocery Hub',
            pincode: '560004',
            buildingName: 'Grocery Plaza',
            street: 'Koramangala',
            area: 'South Bangalore',
            landmark: 'Near Forum Mall',
            city: 'Bangalore',
            state: 'Karnataka',
            personName: 'Nisha Patel',
            mobileNumber: '9876543213',
            whatsappNumber: '9876543213',
            email: 'nisha@groceryhub.com',
            workingDays: 'Mon-Sun',
            openTime: '8:00 AM',
            closeTime: '10:00 PM',
            images: 'store4.jpg',
            beneficiariesName: 'Nisha Patel',
            accountNumber: '987654321101',
            bankName: 'Axis Bank',
            ifscCode: 'AXIS0001234',
        },
        {
            storeName: 'Fashion Corner',
            pincode: '560005',
            buildingName: 'Fashion Mall',
            street: 'MG Road',
            area: 'Bangalore',
            landmark: 'Near Brigade Road',
            city: 'Bangalore',
            state: 'Karnataka',
            personName: 'Priya Rao',
            mobileNumber: '9876543214',
            whatsappNumber: '9876543214',
            email: 'priya@fashioncorner.com',
            workingDays: 'Mon-Sun',
            openTime: '10:00 AM',
            closeTime: '9:00 PM',
            images: 'store5.jpg',
            beneficiariesName: 'Priya Rao',
            accountNumber: '987654321102',
            bankName: 'Bank of Baroda',
            ifscCode: 'BARB0001234',
        },
    ];


    const [searchTerm, setSearchTerm] = useState('');
    const [openTime, setOpenTime] = useState('');

    // Function to check if the store is open at the selected time
    const isStoreOpenAtTime = (store) => {
        if (!openTime) return true; // No filter applied
        const [openHour, openMinute] = store.openTime.split(':').map(Number);
        const [closeHour, closeMinute] = store.closeTime.split(':').map(Number);
        const [filterHour, filterMinute] = openTime.split(':').map(Number);

        const isAfterOpening =
            (filterHour > openHour || (filterHour === openHour && filterMinute >= openMinute));
        const isBeforeClosing =
            (filterHour < closeHour || (filterHour === closeHour && filterMinute <= closeMinute));

        return isAfterOpening && isBeforeClosing;
    };

    const filteredStores = storeData.filter(store =>
        store.storeName.toLowerCase().includes(searchTerm.toLowerCase()) &&
        isStoreOpenAtTime(store)
    );

    return (
        <div className="table-container">
            <h2>Store Listing</h2>
            <div className="search-container">
                <input
                    type="text"
                    placeholder="Search by store name..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <div className="filter-container">
                    <label htmlFor="open-time">Open at:</label>
                    <select
                        id="open-time"
                        value={openTime}
                        onChange={(e) => setOpenTime(e.target.value)}
                    >
                        <option value="">Any Time</option>
                        <option value="9:00">9:00 AM</option>
                        <option value="10:00">10:00 AM</option>
                        <option value="11:00">11:00 AM</option>
                        <option value="12:00">12:00 PM</option>
                        <option value="13:00">1:00 PM</option>
                        <option value="14:00">2:00 PM</option>
                        <option value="15:00">3:00 PM</option>
                        <option value="16:00">4:00 PM</option>
                        <option value="17:00">5:00 PM</option>
                        <option value="18:00">6:00 PM</option>
                        <option value="19:00">7:00 PM</option>
                        <option value="20:00">8:00 PM</option>
                    </select>
                </div>
            </div>
            <div className="table-wrapper">
                <table className="bigbull-table">
                    <thead>
                        <tr>
                            <th>Store Name</th>
                            <th>Pincode</th>
                            <th>Building Name</th>
                            <th>Street</th>
                            <th>Area</th>
                            <th>Landmark</th>
                            <th>City</th>
                            <th>State</th>
                            <th>Person Name</th>
                            <th>Mobile Number</th>
                            <th>WhatsApp Number</th>
                            <th>Email ID</th>
                            <th>Working Days</th>
                            <th>Open Time</th>
                            <th>Close Time</th>
                            <th>Images</th>
                            <th>Beneficiaries Name</th>
                            <th>Account Number</th>
                            <th>Bank Name</th>
                            <th>IFSC Code</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredStores.map((store, index) => (
                            <tr key={index}>
                                <td>{store.storeName}</td>
                                <td>{store.pincode}</td>
                                <td>{store.buildingName}</td>
                                <td>{store.street}</td>
                                <td>{store.area}</td>
                                <td>{store.landmark}</td>
                                <td>{store.city}</td>
                                <td>{store.state}</td>
                                <td>{store.personName}</td>
                                <td>{store.mobileNumber}</td>
                                <td>{store.whatsappNumber}</td>
                                <td>{store.email}</td>
                                <td>{store.workingDays}</td>
                                <td>{store.openTime}</td>
                                <td>{store.closeTime}</td>
                                <td><img src={store.images} alt="Store" width="50" /></td>
                                <td>{store.beneficiariesName}</td>
                                <td>{store.accountNumber}</td>
                                <td>{store.bankName}</td>
                                <td>{store.ifscCode}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default StoreListing;
