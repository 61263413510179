// ThankYouPage.jsx
import React from 'react';
import '../CSS/Global.css';

const ThankYouPage = () => {
  // Function to handle email click
  const handleEmailClick = () => {
    const email = "support@bigbullpaisawala.com";
    window.location.href = `mailto:${email}`;
  };

  return (
    <div className='thankyou'>
      <div className='thankyou-card'>
        <p className='thankyou-icon'>
          <i className="fa-solid fa-circle-check"></i>
        </p>
        <h2>Thank You!</h2>
        <p className='thankyou-para'>
          Your ad has been submitted successfully. Our team will contact you within 24 hours.
        </p>
        <p className='thankyou-support'>
          For any support, email at 
          <span className='support-email' onClick={handleEmailClick}>
            support@bigbullpaisawala.com
          </span>
        </p>
        <div className='thankyou-btn'>
          <button>
            <i className="fa-brands fa-whatsapp"></i> +91 77158 63833
          </button>
        </div>
      </div>
    </div>
  );
};

export default ThankYouPage;
