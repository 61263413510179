import { useState, useEffect } from 'react';
import '../CSS/Global.css';

const VendorProfile = () => {
    const [isEditing, setIsEditing] = useState(false);
    const [profileDetails, setProfileDetails] = useState({
        businessName: 'Tech Innovations Pvt. Ltd.', // New field for business name
        name: 'Aditya More',
        contact: '+91-1234567890',
        email: 'example@gmail.com',
        permanentID: '1234567890',
        address: 'Mumbai, Maharashtra - 400020',
        accountNumber: '',
        confirmAccountNumber: '',
        ifsc: '',
        bankName: '',
        password: '',
        confirmPassword: '',
    });
    const [isBankDetailsUpdated, setIsBankDetailsUpdated] = useState(false);

    useEffect(() => {
        const bankUpdated = localStorage.getItem('bankDetailsUpdated');
        if (bankUpdated === 'true') {
            setIsBankDetailsUpdated(true);
        }
    }, []);

    const handleEdit = () => {
        if (isEditing) {
            console.log('Profile saved:', profileDetails);

            if (
                profileDetails.accountNumber &&
                profileDetails.confirmAccountNumber &&
                profileDetails.ifsc &&
                profileDetails.bankName
            ) {
                localStorage.setItem('bankDetailsUpdated', 'true');
                setIsBankDetailsUpdated(true);
            }
        }
        setIsEditing(!isEditing);
    };

    const handleChange = (e) => {
        setProfileDetails({
            ...profileDetails,
            [e.target.name]: e.target.value,
        });
    };

    return (
        <div className="user-profile">
            <div className="my-profile">
                <h2>My Profile</h2>
                <button onClick={handleEdit} className={`edit-btn ${isEditing ? 'save' : 'edit'}`}>
                    {isEditing ? 'Save' : 'Edit'}
                </button>
            </div>
            <div className="user-profile-column">
                <div className="user-profile-left">
                    <h2>Personal Information</h2>
                    <div className="user-profile-img">
                        <img src="https://picsum.photos/100" alt="user-profile" className='user-profile-img1' />
                        <img src="https://picsum.photos/200" alt="user-profile" className='user-profile-img2' />
                    </div>
                    <div className="vendor-profile-business-name">
                        {isEditing ? (
                            <input
                                type="text"
                                name="businessName"
                                value={profileDetails.businessName}
                                onChange={handleChange}
                                placeholder="Enter business name"
                                className="input-field"
                            />
                        ) : (
                            <p className="business-name">{profileDetails.businessName}</p>
                        )}
                    </div>
                    <div className="user-profile-name vendor-profile-name">
                        {isEditing ? (
                            <input
                                type="text"
                                name="name"
                                value={profileDetails.name}
                                onChange={handleChange}
                                placeholder="Enter your name"
                                className="input-field"
                            />
                        ) : (
                            <p className="user-name">{profileDetails.name}</p>
                        )}
                    </div>
                    <div className="user-profile-contact">
                        <p>
                            Contact: {isEditing ? (
                                <input
                                    type="text"
                                    name="contact"
                                    value={profileDetails.contact}
                                    onChange={handleChange}
                                    placeholder="Enter contact number"
                                    className="input-field"
                                />
                            ) : (
                                <span>{profileDetails.contact}</span>
                            )}
                        </p>
                        <p>
                            Email: {isEditing ? (
                                <input
                                    type="email"
                                    name="email"
                                    value={profileDetails.email}
                                    onChange={handleChange}
                                    placeholder="Enter email"
                                    className="input-field"
                                />
                            ) : (
                                <span>{profileDetails.email}</span>
                            )}
                        </p>
                        <p>
                            Permanent ID: {isEditing ? (
                                <input
                                    type="text"
                                    name="permanentID"
                                    value={profileDetails.permanentID}
                                    onChange={handleChange}
                                    placeholder="Enter permanent ID"
                                    className="input-field"
                                />
                            ) : (
                                <span>{profileDetails.permanentID}</span>
                            )}
                        </p>
                        <p>
                            Address: {isEditing ? (
                                <input
                                    type="text"
                                    name="address"
                                    value={profileDetails.address}
                                    onChange={handleChange}
                                    placeholder="Enter address"
                                    className="input-field"
                                />
                            ) : (
                                <span>{profileDetails.address}</span>
                            )}
                        </p>
                    </div>
                </div>
                <div className="user-profile-right">
                    <div className="vendor-listing">
                        <h2>Your Listings</h2>
                        <div className='listing-card'>
                        <div className='vendor-listing-img'>
                            <img src='https://picsum.photos/200/200' alt='Vendor' /> 
                        </div>
                        <div className='listing-name'>
                            SM Capital One
                        </div>
                        </div>
                    </div>

                    <div className="user-profile-password">
                        <h2>Privacy Details</h2>
                        <div className="password">
                            <input
                                type="password"
                                name="password"
                                value={profileDetails.password}
                                onChange={handleChange}
                                placeholder="Enter your password"
                                className="input-field"
                                disabled={!isEditing}
                            />
                            <input
                                type="password"
                                name="confirmPassword"
                                value={profileDetails.confirmPassword}
                                onChange={handleChange}
                                placeholder="Confirm your password"
                                className="input-field"
                                disabled={!isEditing}
                            />
                        </div>
                    </div>
                    <div className="user-profile-bank">
                        <h2>
                            Bank Details
                            {/* Show the span only if the bank details have not been updated */}
                            {!isBankDetailsUpdated && (
                                <span>
                                    <i className="fa-solid fa-exclamation"></i>
                                    Update Your Bank Details
                                </span>
                            )}
                        </h2>
                        <div className="bank-details">
                            <input
                                type="text"
                                name="accountNumber"
                                value={profileDetails.accountNumber}
                                onChange={handleChange}
                                placeholder="Account Number"
                                className="bank-input"
                                disabled={!isEditing}
                            />
                            <input
                                type="text"
                                name="confirmAccountNumber"
                                value={profileDetails.confirmAccountNumber}
                                onChange={handleChange}
                                placeholder="Confirm Account Number"
                                className="bank-input"
                                disabled={!isEditing}
                            />
                            <input
                                type="text"
                                name="ifsc"
                                value={profileDetails.ifsc}
                                onChange={handleChange}
                                placeholder="IFSC Code"
                                className="bank-input"
                                disabled={!isEditing}
                            />
                            <input
                                type="text"
                                name="bankName"
                                value={profileDetails.bankName}
                                onChange={handleChange}
                                placeholder="Bank Name"
                                className="bank-input"
                                disabled={!isEditing}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VendorProfile ;
