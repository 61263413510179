import { useState } from 'react';  // Missing useState import
import { Routes, Route } from 'react-router-dom';
import Header from './Components/Header';
import Message from './Components/Message'; // Remove duplicate import
import SearchPage from './Components/Search'; 
import Home from './Components/Home';
import WorldPage from './Components/BigBullWorld';
import Register from './Components/Register';
import Login from './Components/Login';
import Grocery from './Components/Grocery';
import Cosmetics from './Components/Cosmetics';
import StoreListing from './Components/StoreListing';
import DetailList from './Components/DetailPage';
import ButtonEverypage from './Components/ButtonForEveryPage';
import ThankYou from './Components/ThankYou';
import WalletPage from './Components/Wallet';
import Userprofile from './Components/UserProfile';
import Vendorprofile from './Components/VendorProfile';
import ShowQR from './Components/ShowQR';
import VendorWallet from './Components/VendorWallet';
import ScanQR from './Components/ScanQR';
import DownloadApp from './Components/Download';
import Padding from './Components/Padding';
import Gallery from './Components/Gallery';
import Product from './Components/Product';
import StoreListingTable from './Components/BigBullForms/StoreListing';
import ProductTable from './Components/BigBullForms/ProductListingTable'
import AdvertiseListing from './Components/BigBullForms/AdvertiseTable';
import VendorTable from './Components/BigBullForms/VendorRegisterTable';
import UserTable from './Components/BigBullForms/UserRegisterTable'
import AllRoute from './Components/AllRoutes/AllRoute';

const App = () => {
  // const [isChatOpen, setIsChatOpen] = useState(false);  // Initialize state for chat

  return (
    <>
      {/* Pass isChatOpen and setIsChatOpen as props to Header */}
      {/* <Header isChatOpen={isChatOpen} setIsChatOpen={setIsChatOpen} /> */}
      <ButtonEverypage />
      
      <AllRoute/>
      {/* Message component should be rendered with isChatOpen props */}
      {/* <Message isChatOpen={isChatOpen} setIsChatOpen={setIsChatOpen} /> */}
      <DownloadApp />
      <Padding />
    </>
  );
};

export default App;
